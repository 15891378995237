import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import NoData from "../images/No search Data.png";
import five from "../images/five.png";
import four from '../images/400.png'
import { useLocation } from "react-router-dom";
import { popular_search } from "../homePage/homeState";
import { useNavigate } from "react-router-dom";

import CryptoJS from "crypto-js";

const HomeSearch = () => {
  const [hoverStates, setHoverStates] = useState({});
  const [error500, setError500] = useState(false); // Add error state for 500 status

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search") || "";

  const [popularSearches, setPopularSearches] = useState([]);
  const [error404, setError404] = useState(false); // Add error state for 500 status
  useEffect(() => {
    // Fetch search suggestions when the component mounts or search query changes
    popular_search(searchQuery)
      .then((res) => {
        console.log("API Response:", res.data);
        setPopularSearches(res.data.popular_searches || []); // Update the state with the fetched data
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        }else if (err.response.status === 404) {
          setError404(true); // Handle 404 error
      }else if(err.response.status === 401){
        localStorage.removeItem("auth_token");
        navigate("/");

        // Optionally reload the page to ensure UI state updates
        window.location.reload();
      }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]); // Fetch results when searchQuery changes

 
  const filteredResults = Array.isArray(popularSearches)
    ? popularSearches.filter((item) =>
        item.content_title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const handleHover = (index, isHovered) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [index]: isHovered,
    }));
  };
  const navigate = useNavigate();


  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

 

  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh", // Ensure the content can overflow if needed
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
         {error500 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={five}
                        alt="Error 500"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/* Render 404 Error Image */}
            {error404 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={four}
                        alt="Error 404"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}
              {!error500 && !error404 && (
                <>
      <Grid classes="data"></Grid>
      <Grid
        sx={{
          position: "relative",
          paddingTop: { xs: "12rem", sm: "12rem", md: "12rem", lg: "12rem" },
          paddingBottom: "5rem",
          paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
        }}
      >
        <Grid classes="content_gradient_sub"></Grid>
        <Grid classes="recent_gradient"></Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "2rem" }}>
            <Typography variant="subTitle1">Popular search</Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

        {filteredResults.length === 0 && !error500 ? (
          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5rem", // Adjust spacing below divider
              paddingBottom: "5rem", // Add some spacing below the image
            }}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={NoData}
                  alt="No Data"
                  // style={{ maxWidth: "50%", height: "auto" }}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            // spacing={3}
            rowSpacing={{ xs: 2, sm: 6, md: 5 }}
            columnSpacing={{ xs: 2, sm: 4, md: 1.5, lg: 1.5 }}
            columns={{ xs: 4, sm: 10, md: 15, lg: 15 }}
            pt={10}
          >
            <Grid classes="home_gradient" />
            <Grid classes="home_gradients" />
            <Grid classes="recent_gradient_sub"></Grid>

            {filteredResults &&
              filteredResults.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  key={index}
                  sx={{ paddingBottom: "2rem" }}
                >
                  {/* <img
                  src={item.thumbnail}
                  alt={`Thumbnail ${index}`}
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                /> */}

                  <div
                    style={{
                      
                      position: "relative", // Ensure the image and overlay are positioned relative to the container
                      overflow: "hidden",
                      width: "100%", // You can set a specific width here if needed
                      height: "auto", // Ensure height is based on image aspect ratio or container
                      cursor: "pointer",
                    }}
                    onMouseEnter={() => handleHover(index, true)}
                    onMouseLeave={() => handleHover(index, false)}
                    onClick={() =>
                      handleClick(item.content_id, item.content_type_id)
                    }
                >
                    <img
                      src={item.web_thumbnail}
                      alt={`Thumbnail ${index}`}
                      style={{
                        width: "100%",
                        height: "auto", // Maintain aspect ratio
                        borderRadius:"11.36px",
                        objectFit: "cover", // Ensure the image fills the container
                        // position: "absolute",
                        // top: 0,
                        // left: 0,
                        // objectFit: "cover",
                        transition: "transform 0.3s ease-in-out",
                        transform: hoverStates[index]
                          ? "scale(1.05)"
                          : "scale(1)", // Slight zoom
                      }}
                    />
                    {/* <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "100%", // Full height of the container
                        // background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%)", // Black gradient
                        background:
                          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)", // Custom gradient

                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end", // Align content to the bottom
                        padding: "0.5rem",
                        opacity: hoverStates[index] ? 1 : 0,
                        transition:
                          "opacity 0.3s ease-in-out, background 0.3s ease-in-out",

                        pointerEvents: "none", // Prevents the overlay from blocking clicks
                        boxSizing: "border-box", // Ensure padding does not affect height calculation
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8px", // Small screens
                            sm: "8px", // Medium screens
                            md: "8px", // Large screens
                            lg: "12px",
                            xl: "20px",
                          },
                          lineHeight: {
                            xs: "8px", // Small screens
                            sm: "16px", // Medium screens
                            md: "30px", // Large screens
                            lg: "28px",
                            xl: "30px",
                          },

                          color: "white",
                          padding: 0,
                          margin: 0,
                          fontFamily: "inter !important",
                        }}
                      >
                        {item.content_title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8px", // Small screens
                            sm: "6px", // Medium screens
                            md: "6px", // Large screens
                            lg: "10px",
                            xl: "12px",
                          },
                          lineHeight: {
                            xs: "8px", // Small screens
                            sm: "8px", // Medium screens
                            md: "12px", // Large screens
                            lg: "12px",
                            xl: "20px",
                          },
                          padding: 0,
                          margin: "0",
                          fontFamily: "inter !important",
                          color: "white",
                          // textOverflow: "ellipsis", // Handle text overflow
                          // whiteSpace: "nowrap", // Prevent text wrapping
                          // overflow: "hidden", // Hide overflowed text
                          display: "-webkit-box", // Enable flexbox for truncation
                          WebkitLineClamp: 3, // Limit to 3 lines
                          WebkitBoxOrient: "vertical", // Set box orientation
                          overflow: "hidden",
                        }}
                      >
                        {item.content_description}
                      </Typography>
                    </div> */}
                     <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%", // Full height of the image container
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end", // Align title/description to the bottom
      background: hoverStates[index]
        ? "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)"
        : "transparent", // Only show background on hover
      opacity: hoverStates[index] ? 1 : 0, // Control visibility with hover
      transition: "opacity 0.3s ease-in-out, background 0.3s ease-in-out",
      pointerEvents: "none", // Prevent the overlay from blocking clicks
      padding: "1rem", // Add padding for title/description
      boxSizing: "border-box",
    }}
  >
    {/* Title */}
    <Typography
      sx={{
        fontSize: {
          xs: "12px", // Small screens
          sm: "12px", // Medium screens
          md: "14px", // Larger screens
          lg: "16px",
          xl: "18px",
        },
        lineHeight: {
          xs: "16px", // Small screens
          sm: "18px", // Medium screens
          md: "22px", // Larger screens
          lg: "24px",
          xl: "26px",
        },
        color: "white",
        fontFamily: "Inter, sans-serif",
        padding: 0,
        margin: 0,
      }}
    >
      {item.content_title}
    </Typography>

    {/* Description */}
    <Typography
      sx={{
        fontSize: {
          xs: "10px", // Small screens
          sm: "10px", // Medium screens
          md: "12px", // Larger screens
          lg: "14px",
          xl: "16px",
        },
        lineHeight: {
          xs: "14px", // Small screens
          sm: "14px", // Medium screens
          md: "16px", // Larger screens
          lg: "18px",
          xl: "20px",
        },
        color: "white",
        marginTop: "4px", // Add some space between title and description
        fontFamily: "Inter, sans-serif",
        display: "-webkit-box", // Enable multiline truncation
        WebkitLineClamp: 2, // Limit to 2 lines
        WebkitBoxOrient: "vertical",
        overflow: "hidden", // Hide overflow text
      }}
    >
      {item.content_description}
    </Typography>
  </div>

                  </div>

                  <Typography
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      color: "rgba(249, 249, 249, 0.8)",

                      fontFamily: "Inter",
                      fontSize: "16.797px",
                      fontWeight: 400,
                      marginLeft: "5px",
                    }}
                  >
                    {item.content_title}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
      </>)}
    </div>
  );
};

export default HomeSearch;
