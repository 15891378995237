import { createTheme } from "@mui/material/styles";
// import {createTheme, responsiveFontSizes} from '@mui/material/styles'

const heightInPixels = 560; // Height in pixels
const viewportHeight = window.innerHeight; // Viewport height in pixels

const heightPercentage = (heightInPixels / viewportHeight) * 100;

const FONT = {
  FAMILY: {
    RALEWAY: "Raleway",
    POPPINS: "Poppins",
    PTSANS: "PT Sans",
  },
  SIZES: {
    12: "0.75rem",
    14: "0.9rem",
    16: "1rem",
    18: "1.125rem",
    20: "1.25rem",
    22: "1.375rem",
    24: "1.5rem",
    36: "2.25rem",
    30: "1.875rem",
    19: "1.188rem",
    44: "2.75rem",
    56: "3.5rem",
  },
  WEIGHT: {
    BOLD: 800,
    SEMI_BOLD: 700,
    DEMI_BOLD: 600,
    MEDIUM: 500,
    REGULAR: 400,
    LIGHT: 300,
  },
};

const theme = createTheme({
  //Typography



  typography: {
    h1: {
      fontFamily: "Inter !important",
      fontSize: "13px",
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "3px",
      color: "#FFFFFF",
    //   "@media only screen and (min-width: 686px) and (max-width: 1285px)": {
    //     fontSize: "10px",
    // },

  },
    h2: {
      fontFamily: "Inter !important",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "14px",
      color: "#FFF",
     
     " @media (min-width: 1200px) and (max-width: 1249px)": {
        fontSize: "15px",
      },
    //   "@media only screen and (min-width: 686px) and (max-width: 1285px)": {
    //     fontSize: "10px",
    // },
  },
    h3: {
      fontFamily: "Inter !important",
      fontSize: "13px !important",
      lineHeight: "20px !important",
      fontWeight: 400,
      textAlign: "center",
      paddingBottom: "1rem",
      positiion: "relative",
      
     
      color: "white",

    },
h6:{
  fontFamily: "Inter !important",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "25.99px",
  letterSpacing: "0.9px",
  color: "rgba(255, 255, 255, 1)",
  textAlign: "start",
  paddingLeft: "16px",
},
   
   

    h4: {
    //   fontFamily: "Inter !important",
    //   fontSize: "42px",
    //   fontWeight: 600,
    //   lineHeight: "55px",
    //   textAlign: "center",
     
    //   backgroundBlendMode: "color",
    //   backgroundClip: "text",
    //   WebkitBackgroundClip: "text",
    //   WebkitTextFillColor: "transparent",
    //  position: "relative",
    //   background:'radial-gradient(187.9% 485.46% at 6.88% 15.79%, #FFFFFF 2.11%, #702CFF 56.28%)' /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */


          fontFamily: "Inter !important",
          fontSize: "42px",
          fontWeight: 600,
          lineHeight: "55px",
          textAlign: "center",
          position: "relative",
          background: 'radial-gradient(187.9% 485.46% at 6.88% 15.79%, #FFFFFF 13.11%, #702CFF 38.28%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundBlendMode: 'color',


      
    },
    h5 : {
      fontFamily: "Inter !important",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "32px",
      textAlign: "center",
      color: "rgba(255, 255, 255, 1)",
      position: "relative",
    },
h7:{
  fontFamily: "Inter !important",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "26px",
  letterSpacing: "0.9px",
  color: "rgba(255, 255, 255, 1)",
  textAlign: "start",
  padding: "16px",
},

h8:{
  fontFamily: "Inter !important",
  fontWeight: 700,
//  fontSize: "38.574px",
  lineHeight: "26px",
  letterSpacing: "0.9px",
  color: "#fff",
  // fontSize: { xs: "10px", sm: "38.574px", md: "38.574px",lg:"38.574px" }, // Responsive font size
   position:'relative',


 
},
body1:{
  fontFamily: 'Inter',
  width:'100%',
//  fontSize: '24.84px',
  // fontSize: { xs: "16px", sm: "24.84px", md: "24.84px",lg:"24.84px" }, // Responsive font size
  fontWeight: 400,
  lineHeight: '39px',
  textAlign: 'left',
  color: '#F8F8F899',
  paddingTop: '1rem',
  paddingBottom: '1rem',
},
body2:{
  fontFamily: 'Inter',
  fontSize: '27.55px',
  fontWeight: 400,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  lineHeight:' 33.34px',
  color:'#FFF'


},
body3:{
  fontFamily: 'Inter',
  // fontSize: '24.84px',

  fontWeight: 400,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  lineHeight:' 33.34px',
  color:'#FFF'


},

subTitle1:{
  fontFamily: "Inter !important",
  fontWeight: 600,
  fontSize: "28px",
  lineHeight: "26px",
  letterSpacing: "0.9px",
  color: "#fff",
   paddingBottom:'2rem',
   position:'relative',
  // textAlign: "start",
  // padding: "16px",
},

   

subTitle2:{
  color:'#CD59E2',
  fontWeight: 600,
  fontSize: "22px",
  lineHeight: "26px",
  fontFamily: "Inter !important",
  float:'right',
  paddingBottom:'2rem',
  position:'relative',

},
  },

  // Button //
  components: {
    

    MuiButton: {
      variants: [
        
        {
          props: {
            variant: "contained",
          },
          style: {
            width: "150px !important", // Hugs 72px
            height: "32px !important", // Hugs 32px

            borderRadius: "99px 99px 99px 99px !important", // Border radius
            // opacity: '0', // Opacity
            color: "white !important",
            fontWeight: "500px !important",
            fontSize: "14px !important",
            lineHeight: "20px !important",
            textTransform: "inherit !important",
            fontFamily: "Inter important",
            background: "transparent",
            boxShadow: "none",
            "&:hover": {
              background: "transparent",
              boxShadow: "none",
            },
          },
        },

        {
          props: {
            classes: "banners_button_landingPage",
          },
          style: {
            background:
            "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
          color: "#fff",
          border: "1.597px solid transparent",
          borderRadius: "60px",
          textTransform: "initial",
          fontSize: "25px",
          fontWeight: 700,
          lineHeight: "38.73px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontFamily: "Inter !important",
          boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
          position: "relative",
          opacity: 0.85,
          height: "55px",
          backdropFilter: "blur(1px)",
          padding: "0px 45px",
          },
        },
       





        {
          props: {
            variant: "containedhome1",
          },
          style: {
            background:
              "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
            color: "#fff",

            border: "1.597px solid transparent",
            borderRadius: "60px",
            textTransform: "initial",
            fontSize: "25px",
            fontWeight: 700,
            lineHeight: "38.73px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "Inter !important",
            boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
            float: "right",
            marginRight: "200px",
            position: "relative",
                 opacity: 0.85, // Slightly transparent
            height: "55px",
            backdropFilter: "blur(1px)",
            padding:'0px 45px',
            marginTop:'-362px',
            right:'-6%',
            "@media only screen and (min-width: 1700px) and (max-width: 1800px)": {
              marginTop:'-329px',
              right:'-9%'
            },

            "@media only screen and (min-width: 1400px) and (max-width: 1500px)": {
              marginTop:'-305px',
              right:'-15%'
            },
            "@media only screen and (min-width: 1501px) and (max-width: 1699px)": {
              marginTop:'-310px',
              right:'-12%'
            },
            "@media only screen and (min-width: 1024px) and (max-width: 1030px)": {
              marginTop:'-230px',
              right:'-6%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },


            "@media only screen and (min-width: 1035px) and (max-width: 1420px)": {
              marginTop:'-235px',
              right:'-5%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },

            "@media only screen and (min-width: 1114px) and (max-width: 1420px)": {
              marginTop:'-270px',
              right:'-20%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },
            "@media only screen and (min-width: 900px) and (max-width: 1135px)": {
              marginTop:'-224px',
              right:'-36%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },
            "@media only screen and (min-width: 768px) and (max-width: 899px)": {
              marginTop:'-195px',
              right:'-15%',
              padding:'0px 15px',
              height:'40px',
              fontSize:'18px'
            },
            "@media only screen and (min-width: 1900px) and (max-width: 2056px)": {
              marginTop:'-366px',
              right:'-4%'
            },
            "@media only screen and (min-width: 2057px) and (max-width: 2560px)": {
              marginTop:'-392px',
              right:'-2%'
            },

            
          },
        },
        {
          props: {
            variant: "contained1",
          },
          style: {
            background:
              "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
            color: "#fff",

            border: "1.597px solid transparent",
            borderRadius: "60px",
            textTransform: "initial",
            fontSize: "25px",
            fontWeight: 700,
            lineHeight: "38.73px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "Inter !important",
            boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
            float: "right",
            marginRight: "200px",
            position: "relative",
            opacity: 0.85, // Slightly transparent
            height: "55px",
            backdropFilter: "blur(1px)",
            padding:'0px 45px',
            marginTop:'-330px',
            right:'4%',
            "@media only screen and (min-width: 1700px) and (max-width: 1800px)": {
              marginTop:'-339px',
              right:'2%'
            },

            "@media only screen and (min-width: 1400px) and (max-width: 1500px)": {
              marginTop:'-270px',
              right:'-1%'
            },
            "@media only screen and (min-width: 1501px) and (max-width: 1699px)": {
              marginTop:'-306px',
              right:'1%'
            },
            "@media only screen and (min-width: 1024px) and (max-width: 1030px)": {
              marginTop:'-230px',
              right:'-6%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },


            "@media only screen and (min-width: 1035px) and (max-width: 1420px)": {
              marginTop:'-235px',
              right:'-5%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },

            "@media only screen and (min-width: 1114px) and (max-width: 1420px)": {
              marginTop:'-235px',
              right:'-2%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },
            "@media only screen and (min-width: 900px) and (max-width: 1135px)": {
              marginTop:'-195px',
              right:'-6%',
              padding:'0px 20px',
              height:'40px',
              fontSize:'22px'
            },
            "@media only screen and (min-width: 768px) and (max-width: 899px)": {
              marginTop:'-166px',
              right:'-15%',
              padding:'0px 15px',
              height:'40px',
              fontSize:'18px'
            },
            "@media only screen and (min-width: 1900px) and (max-width: 2056px)": {
              marginTop:'-361px',
              right:'5%'
            },
            "@media only screen and (min-width: 2057px) and (max-width: 2560px)": {
              marginTop:'-361px',
              right:'6%'
            },

            
          },
        },



        {
          props: {
            variant: "contained2",
          },
          style: {
            background:
              "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
            color: "#fff",

            border: "1.597px solid transparent",
            borderRadius: "60px",
            textTransform: "initial",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "38.73px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "Inter !important",
            boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
            float: "right",
        
            position: "relative",
            opacity: 0.85, // Slightly transparent
            height: "35px",
            backdropFilter: "blur(1px)",
            padding:'0px 20px',
            marginTop:'-210px',
          
  
            
          },
        },

        {
          props: {
            variant: "contained3",
          },
          style: {
            background:
              "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
            color: "#fff",
width:"182px",
            border: "1.597px solid transparent",
            borderRadius: "56.819px",
            textTransform: "initial",
            fontSize: "18.74px",
            fontWeight: 700,
            lineHeight: "22.67px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "Inter !important",
            boxShadow: " 0px 18.94px 56.819px 0px #1B0C57",
            float: "right",
            // marginRight: "200px",
            position: "relative",
            opacity: 9.85, // Slightly transparent
            height: "46.84px",
            backdropFilter: "blur(7px)",
            padding:'17.137px 41.534px 17.152px 41.53px',
         
            // marginTop:'-330px',
            // right:'4%',
          

            
          },
        },

        {
          props: {
            variant: "tab",
          },
          style: {
            background:
              "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
            color: "#fff",

            border: "3px solid transparent",
            borderRadius: "60px",
            textTransform: "initial",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "38.73px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "Inter !important",
            boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
            float: "right",
            marginRight: "200px",
            position: "relative",
            opacity: 20,
            height: "80px",
          },
        },
        {
          props: {
             variant:"Submit_feedback"
          },
          style: {

            width: '322px',
  height: '51px',
  padding: '16px 100px', // Adjust padding if necessary
          
          
            borderRadius: '30px ',
          
            opacity:'0px',
            background:
            "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
          color: "#fff",


          border: "1.597px solid transparent",

          boxShadow: '0px 18px 30px 0px #1B0C57',

       

          textTransform: "initial",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.36px",
          fontFamily:' Inter !important',
          boxSizing: 'border-box', // Ensures padding/border included in width/height
          overflow: 'hidden', // Ensure text overflow does not resize the button
          display: 'inline-block', // Keeps the button size consistent
         
          





          },
        },

      
      ],
    },


    MuiInputBase: {
      variants: [
        {
          props: { classes: 'searchInput' },
          style: {
            fontFamily: 'Inter !important',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '22px',
            letterSpacing: '-0.40799999237060547px',
            textAlign: 'left',
            width: '100%',
            paddingLeft: '30px', // Add padding to make room for the icon
            color: 'rgba(244, 244, 244, 1)',
           '& .MuiInputBase-input::placeholder': {
            color: 'rgba(179, 146, 212, 1)',

              fontFamily: 'Inter !important',
              fontSize: '14px', // Change this to desired font size
              fontWeight: 600,
              lineHeight: '22px',
              letterSpacing: '-0.40799999237060547px',
              textAlign: 'left',
            },
          },
        },
        // You can add more variants here if needed
      ],
    },


    MuiGrid: {
      variants: [
        {
          props: {
            classes: "top_gradinet",
          },
          style: {
            width: "412px",
            // height: "560px",
            height: `${heightPercentage}%`,
            top: "-148px",
            left: " -91.86px",
            gap: "0px",
            opacity: "0px",
            filter: "blur(150px)",
            flexShrink: 0,
            background: "rgba(118, 64, 232, 1)",
            borderRadius: "560px",
            position: "absolute",
          },
        },
        {
          props: {
            classes: "mid_gradinet",
          },
          style: {
            width: "1440px",
            height: "100%",
            // height: "405px",
            top: " 490px",
            gap: "0px",
            opacity: "0px",
            background:
              "linear-gradient(180.1deg, rgba(27, 10, 40, 0) 44.79%, rgba(27, 10, 40, 0.401553) 56.48%, rgba(27, 10, 40, 0.674889) 67.63%, #1B0A28 86.49%)",
            filter: " blur(9px)",
          
            flexShrink: 0,
          },
        },

        {
          props: {
            classes: "Footer",
          },
          style: {
            overflow: "hidden",
            background: "#190C25",
           height: "318px",
          //  textAlign:'center',
          //  justifyContent:'center',
           display:'flex',
            // width:'1440px',
            // positiion:'fixed',
            bottom: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            borderRadius: "1px",
            position: "relative",
            paddingBottom: "2rem",
           paddingTop: "4rem",
            paddingLeft: "20rem",
            paddingRight: "20rem",
            "@media (max-width: 375px)": {
              height: "800px !important",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem", // Override padding for screens up to 960px wide
            },
            "@media (max-width: 425px)": {
              height: "800px !important",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem", // Override padding for screens up to 960px wide
            },
            "@media only screen and (min-width: 768px) and (max-width: 1000px)": {
              // height: "450px",
              height: "100%",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem", // Override padding for screens up to 960px wide
            },
            "@media only screen and (min-width: 426px) and (max-width: 767px)" :{
              height: "100%",
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft:'2rem',
              paddingRight:'2rem', // Override padding for screens up to 960px wide
            },
           

           "@media only screen and (min-width: 1024px) and (max-width: 1043px)" :{

            paddingBottom: "3rem",
            paddingTop: "3rem",
            paddingLeft:'7rem',
            paddingRight:'7rem',

           },
         "@media only screen and (min-width: 1000px) and (max-width: 1285px)": {
          paddingBottom: "3rem",
          paddingTop: "3rem",
          paddingLeft:'13rem',
          paddingRight:'13rem',
       
          },
        //   "@media only screen and (min-width: 686px)": {
        //     paddingBottom: "3rem",
        //     paddingTop: "3rem",
        //     paddingLeft:'8rem',
        //     paddingRight:'8rem',
        //     },
             "@media screen and (min-device-width: 1044px) and (max-device-width: 1213px)": {
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft:'10rem',
              paddingRight:'10rem', // Override padding for screens up to 960px wide
            },
            "@media screen and (min-device-width: 1214px) and (max-device-width: 1221px)": {
              paddingBottom: "3rem",
              paddingTop: "3rem",
              paddingLeft:'13rem',
              paddingRight:'13rem', // Override padding for screens up to 960px wide
            },
            "@media screen and (min-device-width: 1222px) and (max-device-width: 1390px)": {
              paddingBottom: "3rem",
              paddingTop: "3rem",
              // paddingLeft:'15rem',
              // paddingRight:'15rem', // Override padding for screens up to 960px wide
               paddingLeft:'3rem',
              paddingRight:'3rem',

            },
          },
        },




        {
          props: {
            classes: "sub_Footer",
          },
          style: {
            paddingTop: "3rem",
            paddingBottom: "3rem",
            paddingLeft: "5rem",
            paddingRight: "5rem",
            "@media (max-width: 375px)": {
              paddingLeft: 0,
              paddingRight: 0, // Override padding for screens up to 960px wide
            },
            "@media (max-width: 768px)": {
              paddingLeft: 0,
              paddingRight: 0, // Override padding for screens up to 960px wide
            },
            "@media (max-width: 1024px)": {
              paddingLeft: 0,
              paddingRight: 0, // Override padding for screens up to 960px wide
            },
          },
        },

        {
          props: {
            classes: "footer_gradient_sub1",
          },


          style: {
           width: "90%",
            height: "240px",
            flexShrink: 0,
            borderRadius: "1440px",
            background:'rgba(118, 64, 232, 0.46)',
           


            filter: "blur(60px)",
          top:'0%',
          left: "-5%",
            position: "absolute",
            // marginTop:'80px',
          },
        },
      

        {
          props: {
            classes: "footer_gradient_sub2",
          },
          style: {
            width: "455px",
            height: "318.82px",
            flexShrink: 0,
            borderRadius: "455px",
            background: "rgba(88, 40, 167, 0.60)",
           filter: "blur(262px)",
            top: "266.97px",
            left: "-105px",
            position: "absolute",
          },
        },
        {
          props: {
            classes: "content_gradient",
          },
          style: {
            width: "648.4px",
            height: "680.82px",
            top: "3180px",
            left: "-218px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 0.4)",
            filter: "blur(250px)",
            zIndex: 50,
            position: "absolute",
          },
        },

        {
          props: {
            classes: "card_grid",
          },
          style: {
            width: "412px",
            // height: "560px",
            height: `${heightPercentage}%`,
            top: "-148px",
            left: " -91.86px",
            gap: "0px",
            opacity: "0px",
            filter: "blur(150px)",
            flexShrink: 0,
            background: "rgba(118, 64, 232, 1)",
            borderRadius: "560px",
            position: "absolute",
          },
        },


/////////////////////////////////////////////Card Gradinet sections/////////////////////////////////////////////////////////

        {
          props: {
            classes: "card_gadient_1",
          },
          style:{
            width: "300.01px",
          
            height: "612.45px",
          
            left: "50%",
            marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
          filter: "blur(180px)",
           
            position: "absolute",
          }
        },
        {
          props: {
            classes: "card_gadient_2",
          },
         
        style:{
          width: "230.01px",
          //   height: "680.82px",
          height: "612.45px",
          // top: "1239px",
          left: "-3%",
          top:'-5%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
        filter: "blur(180px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },
        {
          props: {
            classes: "card_gadient_3",
          },
         
          style:{
           
            width: "500.01px",
        
            height: "400.45px",
           
            left: "79.8%",
            marginTop:'35%',
           
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(150px)",
  
            position: "absolute",
          }
        },
        {
          props: {
            classes: "card_gadient_4",
          },
         
          style:{
           
            width: "300px",
         
          height: "250.45px",
        
          top: "50%",
          left: "1264px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
           filter: "blur(190px)",
     
          position: "absolute",
          }
        },
        {
          props: {
            classes:"home_button_gradients"
          },
         
          style:{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" } ,
            // width: "181px",
            width: "300px",
            //   height: "680.82px",
            height: "993.45px",
          //  bottom: "65%",
          //   // left: "1655px",
          //   left: "80%",
            border:'1px',
            background: 'linear-gradient(170.07deg, rgba(188, 46, 255, 0.72) 9.43%, rgba(128, 76, 238, 0) 117.55%)',
            
            position: 'absolute',
         zIndex:"9.999999",
          right:'2px',
           transform:'rotate(87.26deg)',
          filter: 'blur(80.56px)',
            marginTop:"-50rem",
            gap: "0px",
            opacity: "9px",
          }
        },



        {
          props: {
            classes:"home_button_gradient"
          },
         
          style:{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" } ,
            // width: "181px",
            width: "160px",
            //   height: "680.82px",
            height: "993.45px",
           bottom: "55%",
            // left: "1655px",
            // left: "80%",
            border:'1px',
            background: 'linear-gradient(170.07deg, rgba(188, 46, 255, 0.72) 9.43%, rgba(128, 76, 238, 0) 117.55%)',
            
            position: 'absolute',
            zIndex:"9.999999",
          right:'2px',
           transform:'rotate(87.26deg)',
          // filter: 'blur(80.56px)',
            marginTop:"-50rem",
            gap: "0px",
            opacity: "9px",
          }
        },
        {
          props: {
            classes:"Banner_button_gradient"
          },
         
          style:{
            // display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" } ,
            // width: "181px",
            
            width: "160px",
            //   height: "680.82px",
            height: "993.45px",
           bottom: "75%",
            // left: "1655px",
            left: "75%",
            border:'1px',
            background: 'linear-gradient(170.07deg, rgba(188, 46, 255, 0.72) 9.43%, rgba(128, 76, 238, 0) 117.55%)',
            
            position: 'absolute',
         
          
           transform:'rotate(87.26deg)',
         filter: 'blur(80.56px)',
            marginBottom:"90px",
            gap: "0px",
            opacity: "0px",

            
            "@media (min-width: 1024px) and (max-width: 1249px)": {
              bottom: "60%",
            },

            "@media (min-width: 1440px) and (max-width: 1500px)": {
              bottom: "90%",
            },

           
          }
        },
        {
          props: {
            classes: "recent_gradient",
          },
         
        style:{
          width: "549px",
      //  marginTop:'130px',

      // marginTop:'520px',
      
      borderRadius:'600.667px',

          //   height: "680.82px",
        //  height: "400.45px",
        // height: "746.45px",
        height: "600.45px",
          // top: "1239px",
          left: "0%",
          top:'0%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
          filter: "blur(170px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },

        {
          props: {
            classes: "recent_gradient_sub",
          },
         
        style:{
          width: "279px",
      //  marginTop:'130px',

      marginTop:'1080px',
      
      borderRadius:'500.667px',

          //   height: "680.82px",
        //  height: "400.45px",
        height: "650.45px",
          // top: "1239px",
          left: "0%",
          top:'0%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
         filter: "blur(190px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },

        {
          props: {
            classes: "recent_gradient_sub_terms",
          },
         
        style:{
          width: "279px",
      //  marginTop:'130px',

      marginTop:'800px',
      
      borderRadius:'500.667px',

          //   height: "680.82px",
        //  height: "400.45px",
        height: "650.45px",
          // top: "1239px",
          left: "0%",
          top:'20%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
         filter: "blur(190px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },

        {
          props: {
            classes: "recent_gradient_subs",
          },
         
        style:{
          width: "279px",
      //  marginTop:'130px',

     marginTop:'600px',
      
      borderRadius:'500.667px',

          //   height: "680.82px",
        //  height: "400.45px",
        height: "650.45px",
          // top: "1239px",
          left: "0%",
          top:'0%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
         filter: "blur(190px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },



        {
          props: {
            classes: "content_gradient_subgradinet",
          },
         
        style:{
          width: "279px",
      //  marginTop:'130px',

     marginTop:'800px',
      
      borderRadius:'500.667px',

          //   height: "680.82px",
        //  height: "400.45px",
        height: "650.45px",
          // top: "1239px",
          left: "0%",
          top:'0%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
         filter: "blur(150px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },

        {
          props: {
            classes: "recently_gradient",
          },
         
        style:{
          width: "200.01px",
      //  marginTop:'130px',

      marginTop:'225px',

          //   height: "680.82px",
        //  height: "400.45px",
        height: "300.45px",
          // top: "1239px",
          left: "0%",
          top:'0%',
          // top:'780px',
          // marginTop:"150px",
          gap: "0px",
          opacity: "0px",
          background: "rgba(118, 64, 232, 1)",
          filter: "blur(90px)",
          //   zIndex:-5,
          position: "absolute",
        }
        },

   
       

        {
          props: {
           
        classes:"home_gradients_terms",
          },
  

          style:{
            // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
            // width: "181px",
            width: "181.201px",
            //   height: "680.82px",
            height: "750.425px",
            borderRadius:"893.425px",
            border:"1px solid #230E60",
          //  top: "56.6%",
           flexShrink: 0,
          right:'19%',
            background: 'linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)',
            
            position: 'absolute', // Position absolutely within the parent container
         
          // marginTop:'58rem',
          // marginTop:'65rem',
           transform:'rotate(87.26deg)',
           filter: 'blur(90.56px)',
          
            gap: "0px",
            opacity: "0px",
          }
        },



        {
          props: {
           
        classes:"home_gradients_wishlist",
          },
  

          style:{
            // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
            // width: "181px",
            width: "190.201px",
            //   height: "680.82px",
            height: "800.425px",
            borderRadius:"893.425px",
            border:"1px solid #230E60",
           top: "10%",
           flexShrink: 0,
          right:'15%',
            background: 'linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)',
            
            position: 'absolute', // Position absolutely within the parent container
         
          // marginTop:'58rem',
          // marginTop:'65rem',
           transform:'rotate(87.26deg)',
           filter: 'blur(90.56px)',
          
            gap: "0px",
            opacity: "0px",
          }
        },

        {
          props: {
           
        classes:"home_gradients_nodata",
          },
  

          style:{
            // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
            // width: "181px",
            width: "190.201px",
            //   height: "680.82px",
            height: "800.425px",
            borderRadius:"893.425px",
            border:"1px solid #230E60",
           top: "40%",
           flexShrink: 0,
          right:'15%',
            background: 'linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)',
            
            position: 'absolute', // Position absolutely within the parent container
         
          // marginTop:'58rem',
          // marginTop:'65rem',
           transform:'rotate(87.26deg)',
           filter: 'blur(90.56px)',
          
            gap: "0px",
            opacity: "0px",
          }
        },
        {
          props: {
           
        classes:"home_gradients_about",
          },
  

          style:{
            // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
            // width: "181px",
            width: "190.201px",
            //   height: "680.82px",
            height: "800.425px",
            borderRadius:"893.425px",
            border:"1px solid #230E60",
           top: "25%",
           flexShrink: 0,
          right:'15%',
            background: 'linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)',
            
            position: 'absolute', // Position absolutely within the parent container
         
          // marginTop:'58rem',
          // marginTop:'65rem',
           transform:'rotate(87.26deg)',
           filter: 'blur(90.56px)',
          
            gap: "0px",
            opacity: "0px",
          }
        },
        {
          props: {
           
        classes:"home_gradient",
          },
  

          style:{
            // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
            // width: "181px",
            width: "181.201px",
            //   height: "680.82px",
            height: "750.425px",
            borderRadius:"893.425px",
            border:"1px solid #230E60",
          //  top: "56.6%",
           flexShrink: 0,
          right:'19%',
            background: 'linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)',
            
            position: 'absolute', // Position absolutely within the parent container
         
          // marginTop:'58rem',
          // marginTop:'65rem',
           transform:'rotate(87.26deg)',
           filter: 'blur(90.56px)',
          
            gap: "0px",
            opacity: "0px",
          }
        },
        {
          props: {
           
        classes:"home_gradients",
          },
  

          style:{
            // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
            // width: "181px",
            width: "241.6px",
            //   height: "680.82px",
            height: "750.425px",
            borderRadius:"893.425px",
            border:"1px solid #230E60",
           top: "40.6%",
           flexShrink: 0,
          right:'10%',
            background: 'linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)',
            
            position: 'absolute', // Position absolutely within the parent container
         
          //  marginTop:'10rem',
           transform:'rotate(87.26deg)',
          //  filter: 'blur(100.56px)',
          filter: 'blur(95.56px)',
          
            gap: "0px",
            opacity: "0px",
          }
        },

       

        {
          props: {
           
        classes:"center_gradient",
          },
  

          style:{
            width: '999px',
            height: '747px',
            top: '147px',
            left: '193px',
            gap: '0px',
            opacity:' 0px',
            borderRadius: '999px',
background: '#7640E8',
position: "absolute",
filter: "blur(90px)",
          }
        },
   


        {
          props: {
           
        classes:"live_bottom_gradient",
          },
  

          style:{

            width: '300px',
height: '300px',
left:'-15px',

bottom: '-65.328px',
borderadius: '664px',
background: '#7640E8',
           
position: "absolute",
filter: "blur(120px)",
          }
        },

        {
          props: {
           
        classes:"data",
          },
  

          style:{
            width: '100%', 
         
            height: '236px', 
            top: '-70%', 
            left: '-91.86', // Position from the left edge of the image
          
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)',
            position: 'absolute', // Position absolutely within the parent container
        filter: "blur(12px)",
          }
        },

      ],
    },
  },
});

export default theme;
