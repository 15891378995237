// import React, { useEffect, useState } from "react";
// import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";

// import { useSelector } from "react-redux";
// import BannerImage from "../images/Banner.png";
// import newImage from "../images/newImage.png";
// import { Link, useNavigate } from "react-router-dom";

// import { view_movies } from "./moviesState";

// const MoviesPage = () => {
//   const isSearchMode = useSelector((state) => state.search.isSearchMode);
//   const [activeButton, setActiveButton] = useState("All");

//   const handleButtonClick = (buttonName) => {
//     setActiveButton(buttonName);
//   };


//   const buttonStyle = {
//     background: "#40374D",
//     color: "#FFB7FF",
//     width: "195px",
//     height: "40px",
//     borderRadius: "12px",
//     textTransform: "initial",
//     fontSize: "16px",
//     fontFamily: "Inter !important",
//     padding: "19.06px 20.65px 19.06px 20.65px",
//     "&:hover": {
//       background: "#40374D",
//     },
//   };

//   const activeButtonStyle = {
//     background: "#BD25C0",
//     color: "#FFB7FF",
//     "&:hover": {
//       background: "#BD25C0",
//     },
//   };

//   const TalkStyle = {
//     background: "#40374D",
//     color: "#FFB7FF",
//     width: "140px",
//     height: "40px",
//     borderRadius: "12px",
//     textTransform: "initial",
//     fontSize: "16px",
//     fontFamily: "Inter !important",
//     padding: "19.06px 20.65px 19.06px 20.65px",
//     "&:hover": {
//       background: "#40374D",
//     },
//   };

//   const [movies, setMovies] = useState([]);
//   const [general, setGeneral] = useState([]);
//   const [revivalMessages, setRevivalMessages] = useState([]);
//   const [talkShows, setTalkShows] = useState([]);
//   const [all, setAll] = useState([]);
//   const navigate = useNavigate();

//   const handleClick = (content_id, content_type_id) => {
//     console.log(content_id, content_type_id,"dataa")
//     navigate(`/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`);
//   };

//   console.log(handleClick)
//   useEffect(() => {
//     view_movies()
//       .then((res) => {
//         // Access the list property from the response
//         const data = res.data.list;
// console.log(data,"datsssssss")
//         // Find genres by title
//         const moviesGenre = data.find((section) => section.title === "MOVIES");

//         const AllGenre = data.find(
//           (section) => section.title === "ALL"
//         );
//         const sermonsGenre = data.find(
//           (section) => section.title === "GENERAL SERMONS"
//         );
//         const revivalMessagesGenre = data.find(
//           (section) => section.title === "REVIVAL MESSAGES"
//         );
//         const talkShowsGenre = data.find(
//           (section) => section.title === "TALK SHOWS"
//         );

//         // Set the content for each genre

//         if (AllGenre && AllGenre.content) {
//           setAll(AllGenre.content);
//         }
//         if (moviesGenre && moviesGenre.content) {
//           setMovies(moviesGenre.content);
//         }
//         if (sermonsGenre && sermonsGenre.content) {
//           setGeneral(sermonsGenre.content);
//         }
//         if (revivalMessagesGenre && revivalMessagesGenre.content) {
//           setRevivalMessages(revivalMessagesGenre.content);
//         }
//         if (talkShowsGenre && talkShowsGenre.content) {
//           setTalkShows(talkShowsGenre.content);
//         }
//       })
//       .catch((err) => {
//         console.error("Error fetching data:", err);
//       });
//   }, []);

//   return (
//     <div
//       style={{
//         maxWidth: "100vw",
//         minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
//         backgroundColor: "#1b0a28",
//         position: "relative",
//         overflowX: "hidden",
//         overflowY: "hidden",
//       }}
//     >
//       <Grid classes="data"></Grid>

//       <Grid
//         sx={{
//           position: "relative",
//           paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },

//           paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
//           paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
//         }}
//       >
//         <Grid classes="content_gradient_sub"></Grid>

//         <Grid classes="recent_gradient"></Grid>
//         <Grid
//           container
//           columns={{ xs: 4, sm: 8, md: 12 }}
//           sx={{ position: "relative" }}
//         >
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={6}
//             sx={{ paddingBottom: "2rem", position: "relative" }}
//           >
//             <Typography variant="subTitle1">Movies</Typography>
//           </Grid>
//         </Grid>
//         <Divider
//           style={{
//             border: "1px solid #64748B",
//             position: "relative",
//           }}
//         />

//         <div>
//           <Stack
//             direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
//             spacing={{ xs: 1, sm: 2, md: 2 }}
//             sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}
//           >
//             <Button
//               style={{
//                 background: activeButton === "All" ? "#BD25C0" : "#40374D",

//                 color: "#FFB7FF",
//                 width: "70px",
//                 height: "40px",
//                 borderRadius: "12px",
//                 textTransform: "initial",
//                 fontSize: "16px",
//                 fontFamily: "Inter !important",
//               }}
//               onClick={() => handleButtonClick("All")}
//             >
//               All
//             </Button>
//             <Button
//               sx={{
//                 ...buttonStyle,
//                 ...(activeButton === "General Sermons"
//                   ? activeButtonStyle
//                   : {}),
//                 "@media (max-width: 1024px)": {
//                   fontSize: "15px",
//                 },
//                 "@media (max-width: 1440px)": {
//                   fontSize: "15px",
//                 },
//               }}
//               onClick={() => handleButtonClick("General Sermons")}
//             >
//               General Sermons
//             </Button>
//             <Button
//               sx={{
//                 ...buttonStyle,
//                 ...(activeButton === "Revival Messages"
//                   ? activeButtonStyle
//                   : {}),

//                 "@media (max-width: 1024px)": {
//                   fontSize: "15px",
//                 },
//                 "@media (max-width: 1440px)": {
//                   fontSize: "15px",
//                 },
//               }}
//               onClick={() => handleButtonClick("Revival Messages")}
//             >
//               Revival Messages
//             </Button>
//             <Button
//               onClick={() => handleButtonClick("TALK SHOWS")}
//               sx={{
//                 ...TalkStyle,
//                 ...(activeButton === "TALK SHOWS" ? activeButtonStyle : {}),

//                 "@media (max-width: 1024px)": {
//                   fontSize: "15px",
//                 },
//                 "@media (max-width: 1440px)": {
//                   fontSize: "15px",
//                 },
//               }}
//             >
//               Talk Shows
//             </Button>
//             <Button
//               sx={{
//                 background: "#40374D",
//                 color: "#FFB7FF",
//                 width: "205px",
//                 height: "40px",
//                 borderRadius: "12px",
//                 textTransform: "initial",
//                 fontSize: "16px",
//                 fontFamily: "Inter !important",
//                 padding: "19.06px 20.65px 19.06px 20.65px",
//                 "@media (max-width: 1024px)": {
//                   fontSize: "15px",
//                 },
//                 "@media (max-width: 1440px)": {
//                   fontSize: "15px",
//                 },
//               }}
//             >
//               End Time Prophecy
//             </Button>
//             <Button
//               sx={{
//                 background: "#40374D",
//                 color: "#FFB7FF",
//                 width: "140px",
//                 height: "40px",
//                 borderRadius: "12px",
//                 textTransform: "initial",
//                 fontSize: "16px",
//                 fontFamily: "Inter !important",
//                 padding: "19.06px 20.65px 19.06px 20.65px",
//                 "@media (max-width: 1024px)": {
//                   fontSize: "15px",
//                 },
//                 "@media (max-width: 1440px)": {
//                   fontSize: "15px",
//                 },
//               }}
//             >
//               Kids special
//             </Button>
//           </Stack>
//         </div>
//         {activeButton === 'All' ? (
//         <Box sx={{ textAlign: 'center', position: 'relative',

//           paddingTop: { xs: "3rem", sm: "3rem", md: "3rem", lg: "2rem" },
//           paddingBottom: { xs: "3rem", sm: "3rem", md: "3rem", lg: "2rem" },
//          }}

//         >
//           <Grid
//             container
//             columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}
//             columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
//             // pt={4}
//           >
//             {all.map((image, index) => (
//               <Grid item xs={12} sm={2} md={2} lg={2} key={index}
//               onClick={() => handleClick(image.content_id, image.content_type_id)}
//               >
//                 <img
//                   src={image.thumbnail}
//                   alt={`Thumbnail ${index}`}
//                   style={{
//                     width: '100%',
//                     height: 'auto',
//                     paddingBottom: '1rem',
//                     objectFit: 'cover',
//                     position: 'relative',
//                     cursor:'pointer'
//                   }}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       ) : activeButton === 'General Sermons'  ? (
//   <Box sx={{ textAlign: "center", position: "relative" }}>
//     <Grid
//       container
//       columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}

//       columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
//       pt={4}
//     >
//       <Grid classes="home_gradient" />
//       <Grid classes="home_gradients" />
//       {general.map((image, index) => (
//         <Grid item xs={12} sm={2} md={2} lg={2} key={index}
//         onClick={() => handleClick(image.content_id, image.content_type_id)}
//         >
//           <img
//             src={image.thumbnail}
//             alt={`Thumbnail ${index}`}
//             style={{
//               width: "100%",
//               height: "auto",
//               paddingBottom: "1rem",
//               objectFit: "cover",
//               position: "relative",
//             }}
//           />
//         </Grid>
//       ))}
//     </Grid>
//   </Box>
// )


// : activeButton === "Revival Messages" ? (
//   <Box sx={{ textAlign: "center", position: "relative" }}>
//     <Grid
//       container
//       columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}
//       columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
//       pt={4}
//     >
//       <Grid classes="home_gradient" />
//       <Grid classes="home_gradients" />
//       {revivalMessages.map((image, index) => (
//         <Grid item xs={12} sm={2} md={2} lg={2} key={index}
//         onClick={() => handleClick(image.content_id, image.content_type_id)}
//         >
//           <img
//             src={image.thumbnail}
//             alt={`Thumbnail ${index}`}
//             style={{
//               width: "100%",
//               height: "auto",
//               paddingBottom: "1rem",
//               objectFit: "cover",
//               position: "relative",
//             }}
//           />
//         </Grid>
//       ))}
//     </Grid>
//   </Box>
// ) : activeButton === "Talk Shows" ? (
//   <Box sx={{ textAlign: "center", position: "relative" }}>
//     <Grid
//       container
//       columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}
//       columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
//       // pt={4}
//     >
//       <Grid classes="home_gradient" />
//       <Grid classes="home_gradients" />
//       {talkShows.map((image, index) => (
//         <Grid item xs={12} sm={2} md={2} lg={2} key={index}      onClick={() => handleClick(image.content_id, image.content_type_id)}>
//           <img
//             src={image.thumbnail}
//             alt={`Thumbnail ${index}`}
//             style={{
//               width: "100%",
//               height: "auto",
//               paddingBottom: "1rem",
//               objectFit: "cover",
//               position: "relative",
//             }}
//           />
//         </Grid>
//       ))}
//     </Grid>
//   </Box>
// ) : activeButton === "End Time Prophecy" ? (
//   <Box sx={{ textAlign: "center", position: "relative" }}>
//     <Grid
//       container
//       columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}
//       columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
//       pt={4}
//     >
//       <Grid classes="home_gradient" />
//       <Grid classes="home_gradients" />

//     </Grid>
//   </Box>
// ) : activeButton === "Kids Special" ? (
//   <Box sx={{ textAlign: "center", position: "relative" }}>
//     <Grid
//       container
//       columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}
//       columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
//       pt={4}
//     >
//       <Grid classes="home_gradient" />
//       <Grid classes="home_gradients" />
//       {/* {kidsSpecial.map((image, index) => (
//         <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
//           <img
//             src={image.thumbnail}
//             alt={`Thumbnail ${index}`}
//             style={{
//               width: "100%",
//               height: "auto",
//               paddingBottom: "1rem",
//               objectFit: "cover",
//               position: "relative",
//             }}
//           />
//         </Grid>
//       ))} */}
//     </Grid>
//   </Box>
// ) : (
//   <Grid
//     container
//     spacing={{ xs: 2, sm: 4, md: 3 }}
//     rowSpacing={{ xs: 2, sm: 6, md: 5 }}
//     columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 1.5 }}
//     columns={{ xs: 4, sm: 8, md: 14, lg: 14 }}
//     pt={4}
//   >
//     <Grid classes="home_gradient" />
//     <Grid classes="home_gradients" />
//     <Grid classes="recent_gradient_sub"></Grid>
//     {movies.map((image, index) => (
//       <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
//         <img
//           src={image.thumbnail}
//           alt={`Thumbnail ${index}`}
//           style={{
//             width: "100%",
//             height: "auto",
//             paddingBottom: "1rem",
//             objectFit: "cover",
//             position: "relative",
//           }}
//         />
//       </Grid>
//     ))}
//   </Grid>
// )}

//       </Grid>
//     </div>
//   );
// };

// export default MoviesPage;


import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import five from "../images/five.png";
import four from '../images/400.png'

import { view_movies } from "./moviesState";
import {  useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';


const MoviesPage = () => {

  const [activeButton, setActiveButton] = useState("All");



  const buttonStyle = {
    background: "#40374D",
    color: "#FFB7FF",
    height: "40px",
    borderRadius: "12px",
    textTransform: "capitalize", // Capitalize first letter and lowercase the rest
    fontSize: "16px",
    fontFamily: "Inter !important",
    padding: "19.06px 20.65px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "#40374D",
    },
    // Responsive styling
    "@media (max-width: 1024px)": {
      fontSize: "14px",
      padding: "15px 16px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
      padding: "12px 14px",
    },
    "@media (max-width: 480px)": {
      fontSize: "10px",
      padding: "10px 12px",
    },
  };

  const activeButtonStyle = {
    background: "#BD25C0",
    color: "#FFB7FF",
    "&:hover": {
      background: "#BD25C0",
    },
  };


  const navigate = useNavigate();
  const [hoverStates, setHoverStates] = useState({});
  // const handleClick = (content_id, content_type_id, episode_id) => {
  //   console.log(content_id, content_type_id, "dataa")
  //   navigate(`/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`);
  // };

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };


  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;
    
    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };


  // useEffect(() => {
  //   view_movies()
  //     .then((res) => {
  //       console.log(res, "resss")
  //       // Access the list property from the response
  //       const data = res.data.list;

  //       // Find genres by title
  //       const moviesGenre = data.find((section) => section.title === "ALL");
  //       const sermonsGenre = data.find(
  //         (section) => section.title === "GENERAL SERMONS"
  //       );
  //       const revivalMessagesGenre = data.find(
  //         (section) => section.title === "REVIVAL MESSAGES"
  //       );
  //       const talkShowsGenre = data.find(
  //         (section) => section.title === "TALK SHOWS"
  //       );
  //       const timePhrophecyGenere = data.find(
  //         (section) => section.title === "TALK SHOWS & END TIME PROPHECY"
  //       );
  //       const kidsGenere = data.find(
  //         (section) => section.title === "KIDS"
  //       )

  //       // Set the content for each genre
  //       if (moviesGenre && moviesGenre.content) {
  //         setSeries(moviesGenre.content);
  //       }
  //       if (sermonsGenre && sermonsGenre.content) {
  //         setGeneral(sermonsGenre.content);
  //       }
  //       if (revivalMessagesGenre && revivalMessagesGenre.content) {
  //         setRevivalMessages(revivalMessagesGenre.content);
  //       }
  //       if (talkShowsGenre && talkShowsGenre.content) {
  //         setTalkShows(talkShowsGenre.content);
  //       }
  //       if (timePhrophecyGenere && timePhrophecyGenere.content) {
  //         setEndTimeProphecy(timePhrophecyGenere.content);
  //       }
  //       if (kidsGenere && kidsGenere.content) {
  //         setKids(kidsGenere.content);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching data:", err);
  //     });
  // }, []);
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const [genres, setGenres] = useState({});
  useEffect(() => {
    view_movies()
      .then((res) => {
        console.log(res, "resss");
        const data = res.data.list;
        const genresObject = {};

        data.forEach((section) => {
          if (section.content) {
            genresObject[section.title] = section.content;
          }
        });

        setGenres(genresObject);
        setActiveButton(Object.keys(genresObject)[0] || "All"); // Default to the first genre or 'All'
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response) {
          if (err.response.status === 500) {
              setError500(true); // Handle 500 error
          } else if (err.response.status === 404) {
              setError404(true); // Handle 404 error
          }else if(err.response.status === 401){
            localStorage.removeItem("auth_token");
            navigate("/");
    
            // Optionally reload the page to ensure UI state updates
            window.location.reload();
          }
      }
      });
  }, []);


  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  if (error500) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height to center the image vertically
        }}
      >
        <img
          src={five}
          alt="Error 500"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >

          {/* Background image for 500 error */}
          {error500 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={five}
                        alt="Error 500"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/* Render 404 Error Image */}
            {error404 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={four}
                        alt="Error 404"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}
              {!error500 && !error404 && (
                <>
      <Grid classes="data"></Grid>

      <Grid
        sx={{
          position: "relative",
          paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },

          paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
        }}
      >
        <Grid classes="content_gradient_sub"></Grid>

        <Grid classes="recent_gradient"></Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{ paddingBottom: "2rem", position: "relative" }}
          >
            <Typography variant="subTitle1">Movies</Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

        <div>
        <Stack
            direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          >
            {Object.keys(genres).map((genreTitle) => (
              <Button
                key={genreTitle}
                style={{
                  ...buttonStyle,
                  ...(activeButton === genreTitle ? activeButtonStyle : {}),
                }}
                onClick={() => setActiveButton(genreTitle)}
              >
                {capitalizeFirstLetter(genreTitle)}{" "}
                {/* Apply the function here */}
              </Button>
            ))}
          </Stack>
        </div>
        <Box
          sx={{
            textAlign: "center",
            position: "relative",
            paddingTop: { xs: "3rem", sm: "3rem", md: "3rem", lg: "2rem" },
            paddingBottom: { xs: "3rem", sm: "3rem", md: "3rem", lg: "2rem" },
          }}
        >
          <Grid
            container
            columnSpacing={{ xs: 2, sm: 4, md: 1, lg: 1.5 }}
            columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
            rowSpacing={{ xs: 2, sm: 6, md: 5 }}
          >
            {genres[activeButton]?.map((image, index) => (
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                key={index}
                onClick={() =>
                  handleClick(image.content_id, image.content_type_id)
                }
              >
                <div
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    cursor: "pointer",
                    borderRadius: "8px",
                    width: "100%",
                    height: "0",
                    paddingBottom: "150.25%", // Maintain a 16:9 aspect ratio
                  }}
                  onMouseEnter={() =>
                    setHoverStates((prev) => ({ ...prev, [index]: true }))
                  }
                  onMouseLeave={() =>
                    setHoverStates((prev) => ({ ...prev, [index]: false }))
                  }
                >
                  <img
                    src={image.thumbnail}
                    alt={`Thumbnail ${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      objectFit: "cover",
                      transition: "transform 0.3s ease-in-out",
                      transform: hoverStates[index]
                        ? "scale(1.05)"
                        : "scale(1)", // Slight zoom
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "100%", // Full height of the container
                      background:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)", // Custom gradient
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end", // Align content to the bottom
                      padding: "0.5rem",
                      opacity: hoverStates[index] ? 1 : 0,
                      transition:
                        "opacity 0.3s ease-in-out, background 0.3s ease-in-out",
                      pointerEvents: "none", // Prevents the overlay from blocking clicks
                      boxSizing: "border-box", // Ensure padding does not affect height calculation
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "8px",
                          sm: "8px",
                          md: "8px",
                          lg: "12px",
                          xl: "20px",
                        },
                        lineHeight: {
                          xs: "8px",
                          sm: "16px",
                          md: "30px",
                          lg: "28px",
                          xl: "30px",
                        },
                        color: "white",
                        padding: 0,
                        margin: 0,
                        fontFamily: "inter !important",
                      }}
                    >
                      {image.content_title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "8px",
                          sm: "6px",
                          md: "6px",
                          lg: "10px",
                          xl: "12px",
                        },
                        lineHeight: {
                          xs: "8px",
                          sm: "8px",
                          md: "12px",
                          lg: "12px",
                          xl: "20px",
                        },
                        padding: 0,
                        margin: "0",
                        fontFamily: "inter !important",
                        color: "white",
                        display: "-webkit-box",
                        WebkitLineClamp: 3, // Limit to 3 lines
                        WebkitBoxOrient: "vertical", // Set box orientation
                        overflow: "hidden",
                      }}
                    >
                      {image.content_description}
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>

      </Grid>
      </>
              )}
    </div>






  );
};

export default MoviesPage;


