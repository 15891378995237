import axiosInstance from '../../axiosInstance';
import { apiEndpoint } from "../../components/data/config";


 
export const home_web = async () => {
    const config = {
      method: "get",
      url: `${apiEndpoint}/api/web_home_page`,
      headers: {
        // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
  
        "Content-Type": "application/json",
      },
     
    };
    return axiosInstance(config); // Use axiosInstance instead of axios
  };
  

  export const searchContent = async (query) => {
    try {
      const config = {
        method: 'post',
        url: `${apiEndpoint}/api/app_search_result`,
        data: {
          text: query // Use 'data' for POST request payload
        },
        headers: {
          // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
    
          "Content-Type": "application/json",
        },
        
      };
      return await axiosInstance(config); // Ensure axiosInstance is properly configured
    } catch (error) {
      console.error('Error in searchContent:', error);
      throw error;
    }
  };
  
  
  export const getSuggestions = async () => {
    try {
      const config = {
        method: 'post',
        url: `${apiEndpoint}/api/app_search_suggestions`,
       
        headers: {
          // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
    
          "Content-Type": "application/json",
        },
        
      };
      return await axiosInstance(config); // Ensure axiosInstance is properly configured
    } catch (error) {
      console.error('Error in searchContent:', error);
      throw error;
    }
  };
  

  

    export const Search_movies = async () => {
      const config = {
        method: "get",
        url: `${apiEndpoint}/api/web_movies`,
        headers: {
          // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
    
          "Content-Type": "application/json",
        },
       
      };
      return axiosInstance(config); // Use axiosInstance instead of axios
    };
    
    
    export const Search_series = async () => {
      const config = {
        method: "get",
        url: `${apiEndpoint}/api/web_series`,
        headers: {
          // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
    
          "Content-Type": "application/json",
        },
       
      };
      return axiosInstance(config); // Use axiosInstance instead of axios
    };
    

    export const Search_songs = async () => {
      const config = {
        method: "get",
        url: `${apiEndpoint}/api/web_songs`,
        headers: {
          // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,
    
          "Content-Type": "application/json",
        },
       
      };
      return axiosInstance(config); // Use axiosInstance instead of axios
    };
    