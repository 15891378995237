import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import NoData from "../images/No search Data.png";
import five from "../images/five.png";
import four from '../images/400.png'
import { useLocation, useNavigate } from "react-router-dom";
import { live_tv } from "../livePage/liveState";
import CryptoJS from "crypto-js";

const LiveTvSearch = () => {
  const [channels, setChannels] = useState([]);
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search") || "";

  useEffect(() => {
    live_tv()
      .then((res) => {
        console.log(res);
        setChannels(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        }else if (err.response.status === 404) {
          setError404(true); // Handle 404 error
      }else if(err.response.status === 401){
        localStorage.removeItem("auth_token");
        navigate("/");

        // Optionally reload the page to ensure UI state updates
        window.location.reload();
      }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter data based on the search query
  const filteredData = channels.filter(
    (item) =>
      item.content_title.toLowerCase().includes(searchQuery.toLowerCase())
    // ||
    // item.subtitle.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const navigate = useNavigate();
  // const handleClick = (content_id, content_type_id,episode_id) => {
  //   console.log(content_id, content_type_id,"dataa")
  //   navigate(`/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`);
  // };
  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };



//   const handleClick = (content_id, content_type_id, url_hls,content_title,thumbnail) => {
//     // Construct the query string
//     console.log(content_id, content_type_id, url_hls,"fggfyhtgyyt")
//     const queryString = `content_id=${content_id}&content_type_id=${content_type_id}&url_hls=${encodeURIComponent(url_hls)}`;

//     console.log(queryString,"queryStringqueryString")

//     // Encrypt the query string if necessary
//     const encryptedQuery = encryptQueryString(queryString);

//     // Navigate to the new page with the encrypted query string
//     navigate(`/livetv?data=${encryptedQuery}`);
// };

const handleClick = (content_id, content_type_id, url_hls, content_title, thumbnail) => {
  // Construct the query string with content_title and thumbnail
  console.log(content_id, content_type_id, url_hls, content_title, thumbnail, "fggfyhtgyyt");
  
  const queryString = `content_id=${content_id}&content_type_id=${content_type_id}&url_hls=${encodeURIComponent(url_hls)}&content_title=${encodeURIComponent(content_title)}&thumbnail=${encodeURIComponent(thumbnail)}`;
  
  console.log(queryString, "queryString");

  // Encrypt the query string if necessary
  const encryptedQuery = encryptQueryString(queryString);

  // Navigate to the new page with the encrypted query string
  navigate(`/livetv?data=${encryptedQuery}`);
};


  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
            {error500 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={five}
                        alt="Error 500"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/* Render 404 Error Image */}
            {error404 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={four}
                        alt="Error 404"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}
              {!error500 && !error404 && (
                <>
      <Grid classes="data"></Grid>
      <Grid
        sx={{
          position: "relative",
          // paddingTop: "18rem",
          paddingTop: { xs: "12rem", sm: "12rem", md: "12rem", lg: "12rem" },
          paddingBottom: "5rem",
          // paddingLeft: "10rem",
          // paddingRight: "10rem",
          paddingLeft: { xs: "2rem", sm: "2rem", md: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "10rem" },
        }}
      >
        <Grid classes="content_gradient_sub"></Grid>
        <Grid classes="recent_gradient"></Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "2rem" }}>
            <Typography variant="subTitle1">Live Tv Search</Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

        {filteredData.length === 0 && !error500 ? (
          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5rem", // Adjust spacing below divider
              paddingBottom: "5rem", // Add some spacing below the image
            }}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={NoData}
                  alt="No Data"
                  // style={{ maxWidth: "50%", height: "auto" }}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={3}
            columnSpacing={{ xs: 2, sm: 4, md: 10, lg: 1.5 }}
            columns={{ xs: 4, sm: 10, md: 14, lg: 15 }}
            pt={5}
            sx={{ position: "relative" }}
          >
            <Grid classes="home_gradient" />
            <Grid classes="home_gradients" />
            <Grid classes="recent_gradient_sub"></Grid>

            {filteredData &&
              filteredData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  key={index}
                  sx={{
                    paddingBottom: "2rem",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleClick(item.content_id, item.content_type_id,item.url.url_hls,item.content_title,item.thumbnail)
                  }
                >
                  <img
                    src={item.thumbnail}
                    alt={`Thumbnail ${index}`}
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                    onClick={() =>
                      handleClick(item.content_id, item.content_type_id,item.url.url_hls,item.content_title,item.thumbnail)
                    }
                  />
                  <Typography
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      color: "#F9F9F9",
                      fontFamily: "Inter",
                      fontSize: "22.797px",
                      fontWeight: 400,
                      marginLeft: "5px",
                    }}
                  >
                    {item.content_title}
                  </Typography>
                  <Typography
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      color: "rgba(249, 249, 249, 0.8)",

                      fontFamily: "Inter",
                      fontSize: "16.797px",
                      fontWeight: 400,
                      marginLeft: "5px",
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        )}
        {/* )} */}
      </Grid>
      </>)}
    </div>
  );
};

export default LiveTvSearch;
