// import React, { useEffect, useState } from "react";
// import Loader from "./components/loader/loader";
// import { useRoutes, useLocation } from "react-router-dom";
// import Header from "./components/layout/header";
// import Footer from "./components/layout/Footer/footer";
// import HomePage from "./pages/homePage/banner";
// import LandingPage from "./pages/landingPage/banner";
// import Movies from "./pages/moviesPage/moviesPage";
// import TvSeries from "./pages/tvSeries/tvSeries";
// import Livetv from "./pages/livePage/liveTv";
// import MoviesSearch from "./pages/search/movieSearch";
// import TvSeriesSearch from "./pages/search/tvSeriesSearch";
// import LiveTvSearch from "./pages/search/liveTvSearch";
// import HomeSearch from "./pages/search/homeSearch";
// import FeedBack from "./components/layout/Footer/feedBack";
// import TermsCondition from "./components/layout/Footer/termsCondition";
// import PrivacyPolicy from "./components/layout/Footer/privacyPolicy";
// import AboutUs from "./components/layout/Footer/aboutUs";
// import Wishlist from "./components/layout/Footer/profile/wishlist";
// import History from "./components/layout/Footer/profile/history";
// import NoHistory from "./pages/NoData/historyNoData.js";
// import Nowishlist from "./pages/NoData/wishlistNoData.js";
// import NoSearchResult from "./pages/NoData/searchNoData.js";
// import Account from "./pages/AccountPage/Account.js";
// import VideoPlayer from "./pages/VideoPlayer/videoPlayer.js";
// import { useDispatch } from "react-redux";
// import { checkAuth } from "./redux/authSlice";
// import { CSSTransition, TransitionGroup } from "react-transition-group";

// const App = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const [loading, setLoading] = useState(true); // Manage loader visibility
//   const [shouldDisplayContent, setShouldDisplayContent] = useState(false); // Control content display

//   useEffect(() => {
//     dispatch(checkAuth()); // Check auth status on app load

//     // Simulate initial API call delay
//     const fetchData = async () => {
//       await new Promise((resolve) => setTimeout(resolve, 2000)); // Delay 2s for demonstration
//       setLoading(false); // Set loading to false after API is done
//       setShouldDisplayContent(true); // Show content after initial load
//     };

//     fetchData();
//   }, [dispatch]);
//     useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to top on route change
//   }, [location.pathname]); // Only trigger on path change


//   useEffect(() => {
//     setLoading(true); // Show loader before route change
//     setShouldDisplayContent(false); // Hide content during loading

//     // Simulate API call delay
//     const timer = setTimeout(() => {
//       setLoading(false); // Hide loader after content is loaded
//       setShouldDisplayContent(true); // Show content after loading
//     }, 2000); // Adjust timeout to match your actual API call duration

//     return () => clearTimeout(timer);
//   }, [location.pathname]);

//   const routes = useRoutes([
//     { path: "/homePage", element: <HomePage /> },
//     { path: "/movies", element: <Movies /> },
//     { path: "/tvSeriesPage", element: <TvSeries /> },
//     { path: "/", element: <LandingPage /> },
//     { path: "/livetv", element: <Livetv /> },
//     { path: "/feedback", element: <FeedBack /> },
//     { path: "/terms&condition", element: <TermsCondition /> },
//     { path: "/aboutus", element: <AboutUs /> },
//     { path: "/privacy&policy", element: <PrivacyPolicy /> },
//     { path: "/wishlist", element: <Wishlist /> },
//     { path: "/history", element: <History /> },
//     { path: "/nohistory", element: <NoHistory /> },
//     { path: "/nowishlist", element: <Nowishlist /> },
//     { path: "/account", element: <Account /> },
//     { path: "/videoPlayer", element: <VideoPlayer /> },
//     { path: "/nosearchresult", element: <NoSearchResult /> },
//     { path: "/explore/movies", element: <MoviesSearch /> },
//     { path: "/explore/tvSeries", element: <TvSeriesSearch /> },
//     { path: "/explore/livetv", element: <LiveTvSearch /> },
//     { path: "/explore/popularsearch", element: <HomeSearch /> },
//   ]);

//   return (
//     <div style={styles.pageTransition}>
//       {loading && (
//         <CSSTransition
//           in={loading}
//           timeout={20000}
//           classNames="fade"
//           unmountOnExit
//         >
//           <div className="loader-container">
//             <Loader /> {/* Render the loader component */}
//           </div>
//         </CSSTransition>
//       )}
//       <TransitionGroup>
//         <CSSTransition key={location.pathname} timeout={500} classNames="fade">
//           <>
//             <Header />
//             {routes}
//             <Footer />
//           </>
//         </CSSTransition>
//       </TransitionGroup>
//     </div>
//     //   </CSSTransition>
//     // </TransitionGroup>
//   );
// };

// const styles = {
//   pageTransition: {
//     position: "relative",
//     width: "100%",
//     height: "100%",
//     overflow: "hidden",
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',/* Light shader background */
//   },
// };

// // const css = `
// //   .zoom-enter {
// //     transform: scale(0.8);
// //     opacity: 0;
// //   }
// //   .zoom-enter-active {
// //     transform: scale(1);
// //     opacity: 1;
// //     transition: transform 500ms ease-in, opacity 500ms ease-in;
// //   }
// //   .zoom-exit {
// //     transform: scale(1);
// //     opacity: 1;
// //   }
// //   .zoom-exit-active {
// //     transform: scale(0.8);
// //     opacity: 0;
// //     transition: transform 500ms ease-out, opacity 500ms ease-out;
// //   }

// //   .fade-enter {
// //     opacity: 0;
// //   }
// //   .fade-enter-active {
// //     opacity: 1;
// //     transition: opacity 1000ms ease-in-out;
// //   }
// //   .fade-exit {
// //     opacity: 1;
// //   }
// //   .fade-exit-active {
// //     opacity: 0;
// //     transition: opacity 500ms ease-in-out;
// //   }

// //   .loader-container {
// //     background-color: black;
// //     width: 100vw;
// //     height: 100vh;
// //     display: flex;
// //     justify-content: center;
// //     align-items: center;
// //     position: fixed;
// //     top: 0;
// //     left: 0;
// //     z-index: 9999;
// //   }
// // `;

// // const styleElement = document.createElement("style");
// // styleElement.textContent = css;
// // document.head.appendChild(styleElement);

// // export default App;

// const css = `
//   .zoom-enter {
//     transform: scale(0.8);
//     opacity: 0;
//   }
//   .zoom-enter-active {
//     transform: scale(1);
//     opacity: 1;
//     transition: transform 500ms ease-in, opacity 500ms ease-in;
//   }
//   .zoom-exit {
//     transform: scale(1);
//     opacity: 1;
//   }
//   .zoom-exit-active {
//     transform: scale(0.8);
//     opacity: 0;
//     transition: transform 500ms ease-out, opacity 500ms ease-out;
//   }

//   .fade-enter {
//     opacity: 0;
//   }
//   .fade-enter-active {
//     opacity: 1;
//     transition: opacity 1000ms ease-in-out;
//   }
//   .fade-exit {
//     opacity: 1;
//   }
//   .fade-exit-active {
//     opacity: 0;
//     transition: opacity 500ms ease-in-out;
//   }

//   .loader-container {
//     background-color: rgba(255, 255, 255, 0.8); /* Light shader background */
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 9999;
//   }
// `;

// const styleElement = document.createElement("style");
// styleElement.textContent = css;
// document.head.appendChild(styleElement);
// export default App;



import React, { useEffect, useState } from "react";
import Loader from "./components/loader/loader";
import { useRoutes, useLocation } from "react-router-dom";
import Header from "./components/layout/header";
import Footer from "./components/layout/Footer/footer";
import HomePage from "./pages/homePage/banner";
import LandingPage from "./pages/landingPage/banner";
import Movies from "./pages/moviesPage/moviesPage";
import TvSeries from "./pages/tvSeries/tvSeries";
import Livetv from "./pages/livePage/liveTv";
import MoviesSearch from "./pages/search/movieSearch";
import TvSeriesSearch from "./pages/search/tvSeriesSearch";
import LiveTvSearch from "./pages/search/liveTvSearch";
import HomeSearch from "./pages/search/homeSearch";
import FeedBack from "./components/layout/Footer/feedBack";
import TermsCondition from "./components/layout/Footer/termsCondition";
import PrivacyPolicy from "./components/layout/Footer/privacyPolicy";
import AboutUs from "./components/layout/Footer/aboutUs";
import Wishlist from "./components/layout/Footer/profile/wishlist";
import History from "./components/layout/Footer/profile/history";


import Account from "./pages/AccountPage/Account.js";
import VideoPlayer from "./pages/VideoPlayer/videoPlayer.js";
import SongSearch from "./pages/search/songsSearch.js";
import Songs from "./pages/songs/songs"
import { useDispatch } from "react-redux";
import Contact from "./components/layout/Footer/profile/contact.js";
import { checkAuth } from "./redux/authSlice";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Manage loader visibility
  // eslint-disable-next-line no-unused-vars
  const [shouldDisplayContent, setShouldDisplayContent] = useState(false); // Control content display

  useEffect(() => {
    dispatch(checkAuth()); // Check auth status on app load

    // Simulate initial API call delay
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Delay 2s for demonstration
      setLoading(false); // Set loading to false after API is done
      setShouldDisplayContent(true); // Show content after initial load
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location.pathname]); // Only trigger on path change

  useEffect(() => {
    setLoading(true); // Show loader before route change
    setShouldDisplayContent(false); // Hide content during loading

    // Simulate API call delay
    const timer = setTimeout(() => {
      setLoading(false); // Hide loader after content is loaded
      setShouldDisplayContent(true); // Show content after loading
    }, 500); // Adjust timeout to match your actual API call duration

    return () => clearTimeout(timer);
  }, [location.pathname]);

  const routes = useRoutes([
    { path: "/homePage", element: <HomePage /> },
    { path: "/movies", element: <Movies /> },
    { path: "/tvSeriesPage", element: <TvSeries /> },
    { path: "/", element: <LandingPage /> },
    { path: "/livetv", element: <Livetv /> },
    { path: "/feedback", element: <FeedBack /> },
    { path: "/terms&condition", element: <TermsCondition /> },
    { path: "/aboutus", element: <AboutUs /> },
    { path: "/privacy&policy", element: <PrivacyPolicy /> },
    { path: "/wishlist", element: <Wishlist /> },
    { path: "/history", element: <History /> },
    { path: "/songs", element: <Songs/> },

    { path: "/contact", element: <Contact /> },
    { path: "/account", element: <Account /> },
    { path: "/videoPlayer", element: <VideoPlayer /> },

    { path: "/explore/movies", element: <MoviesSearch /> },
    { path: "/explore/tvSeries", element: <TvSeriesSearch /> },
    { path: "/explore/livetv", element: <LiveTvSearch /> },
    { path: "/explore/songssearch", element: <SongSearch /> },
    { path: "/explore/popularsearch", element: <HomeSearch /> },
  ]);

  return (
    // <div style={styles.pageTransition}>
    //   {loading && (
    //     <CSSTransition
    //       in={loading}
    //       timeout={2000}
    //       classNames="fade"
    //       unmountOnExit
    //     >
    //       <div className="loader-container">
    //         <Loader /> {/* Render the loader component */}
    //       </div>
    //     </CSSTransition>
    //   )}
    //   <TransitionGroup>
    //     <CSSTransition key={location.pathname} timeout={500} classNames="fade">
    //       <>
    //         <Header />
    //         {routes}
    //         <Footer />
    //       </>
    //     </CSSTransition>
    //   </TransitionGroup>
    // </div>

    <div style={styles.pageTransition}>
  {loading && (
    <CSSTransition
      in={loading}
      timeout={500}  // Keep this consistent for both enter and exit
      classNames="fade"
      unmountOnExit
    >
      <div className="loader-container">
        <Loader /> {/* Render the loader component */}
      </div>
    </CSSTransition>
  )}
  <TransitionGroup>
    <CSSTransition key={location.pathname} timeout={500} classNames="zoom">
      <div className="content-wrapper"> {/* Wrapper to apply styles */}
        <Header />
        {routes}
        <Footer />
      </div>
    </CSSTransition>
  </TransitionGroup>
</div>

  );
};

const styles = {
  pageTransition: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "rgba(255, 255, 255, 0.8)", /* Light shader background */
  },
};

const css = `
 .fade-enter {
  opacity: 0;
  transform: scale(0.95); /* Start slightly smaller */
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1); /* End at full size */
  transition: opacity 1200ms cubic-bezier(0.4, 0.0, 0.2, 1), 
              transform 1200ms cubic-bezier(0.4, 0.0, 0.2, 1); /* Smooth scaling and opacity */
}
.fade-exit {
  opacity: 1;
  transform: scale(1); /* Start at full size */
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95); /* Shrink slightly before disappearing */
  transition: opacity 1200ms cubic-bezier(0.4, 0.0, 0.2, 1), 
              transform 1200ms cubic-bezier(0.4, 0.0, 0.2, 1); /* Smooth scaling and opacity */
}

.loader-container {
  background-color: rgba(255, 255, 255, 0.8); /* Light shader background */
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

`;

const styleElement = document.createElement("style");
styleElement.textContent = css;
document.head.appendChild(styleElement);

export default App;
