import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import pause from "../../pages/images/pause frame icon.png";
import forwardIcon from "../../pages/images/forwrad frame icon.png";
import backwardButton from "../../pages/images/Icon Frame.png";
import bigscreenIcon from "../images/bigsize.png";
import SoundOff from "../../pages/images/soundoff.png";
import soundUp from "../../pages/images/soundup.png";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import Resize from "../../pages/images/resize.png";
import five from "../images/five.png";
import four from "../images/400.png";
import { live_tv } from "./liveState";
import screenfull from "screenfull";
import CryptoJS from "crypto-js"; // Ensure you have this library for encryption

import { useLocation, useNavigate } from "react-router-dom";
// import { decryptQueryString } from 'your-decrypt-function'; // Import your decrypt function


// Encrypt the query string

// Decrypt the query string

const LiveTv = () => {
  const location = useLocation();
  const [playing, setPlaying] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [played, setPlayed] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const playerRef = useRef(null);
  const videoRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [channels, setChannels] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState();
  const [thumbnail, setThumbnail] = useState(""); // Ensure this matches your useState
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  // const [selectedTitle, setSelectedTitle] = useState("Comforter Digital Channel (Tamil)");
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const handlePlayPause = () => {
    setPlaying(!playing);
  };
  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", onFullscreenChange);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", onFullscreenChange);
      }
    };
  }, []);

  const handleCardClick = (title, url, thumbnail) => {
    console.log("Selected Video URL:", thumbnail);
    console.log(thumbnail, "thums");
    setCurrentVideoUrl(url);
    setSelectedTitle(title);
    setThumbnail(thumbnail); // Corrected typo here
  };

  console.log(thumbnail, "thhhhh");
  const handleFullscreen = () => {
    const element = containerRef.current;

    // Check if the element exists before trying to make it fullscreen
    if (element) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // IE/Edge
        element.msRequestFullscreen();
      }
    } else {
      console.error("Element is null, cannot request fullscreen.");
    }
  };

  const progressBarRef = useRef(null);
  const handleScrub = (e) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const posX = e.clientX - rect.left;
    const newPlayed = posX / rect.width;
    playerRef.current.seekTo(newPlayed);
    setPlayed(newPlayed);
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      handleScrub(e);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const handleVolumeToggle = () => {
    setMuted(!muted);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        setPlayed(currentTime / duration);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [currentVideoUrl, setCurrentVideoUrl] = useState("");


  const decryptQueryString = (encryptedQuery) => {
    const secretKey = "your-secret-key"; // Use the same key as in encryption
    const decrypted = CryptoJS.AES.decrypt(
      decodeURIComponent(encryptedQuery),
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get("data");

    if (encryptedData) {
      try {
        // Decrypt the query string
        const decryptedQueryString = decryptQueryString(encryptedData);
        const params = new URLSearchParams(decryptedQueryString);
        const url_hls = params.get("url_hls"); // Extract the HLS URL
        const content_title = params.get("content_title");
        const thumbnail = params.get("thumbnail");
        if (url_hls) {
          console.log(url_hls, "url_hls from query string");

          setCurrentVideoUrl((prevUrl) => {
            if (!prevUrl) {
              return url_hls;
            }
            return prevUrl; // If already set, do nothing
          });
          if (content_title) {
            setSelectedTitle(content_title);
          }
          if (thumbnail) {
            setThumbnail(thumbnail);
          }
        } else {
          console.error("No HLS URL found in query string.");
        }
      } catch (error) {
        console.error("Error decrypting data from query string:", error);
      }
    }
  }, [location.search]);

  useEffect(() => {
    live_tv()
      .then((res) => {
        console.log(res, "Live TV Data Response");

        const channelData = res.data;
        console.log(channelData, "chammelDatata");
        // if (channelData.length > 0) {
        //   setChannels(channelData);

        //   // Set the first video URL to play automatically
        //   setCurrentVideoUrl(channelData[0].url.url_hls);
        //   setSelectedTitle(channelData[0].content_title);
        //   setThumbnail(channelData[0].thumbnail);
        // }

        if (channelData.length > 0) {
          setChannels(channelData);

          // Only set the first channel URL if currentVideoUrl is still not set
          setCurrentVideoUrl((prevUrl) => {
            if (!prevUrl) {
              console.log(
                "Setting currentVideoUrl to first channel",
                channelData[0].url.url_hls
              );
              setSelectedTitle(channelData[0].content_title);
              setThumbnail(channelData[0].thumbnail);
              return channelData[0].url.url_hls;
            }
            return prevUrl; // Do nothing if currentVideoUrl is already set
          });
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response) {
          if (err.response.status === 500) {
            setError500(true); // Handle 500 error
          } else if (err.response.status === 404) {
            setError404(true); // Handle 404 error
          } else if (err.response.status === 401) {
            localStorage.removeItem("auth_token");
            navigate("/");

            // Optionally reload the page to ensure UI state updates
            window.location.reload();
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   live_tv()
  //     .then((res) => {
  //       console.log(res,"restss");
  //       setChannels(res.data);
  //       setCurrentVideoUrl(res.data.url.url_hls);
  //     })

  //     .catch((err) => {
  //       console.error("Error fetching data:", err);
  //     });
  // }, [currentVideoUrl]);

  console.log(currentVideoUrl, "currentVideoUrl");

  // console.log(channels,"currentVideoUrl").
  const [currentTime, setCurrentTime] = useState(0); // Current time in seconds

  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const secs = Math.floor(seconds % 60);
  //   return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  // };
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
      secs < 10 ? "0" : ""
    }${secs}`;
  };

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      console.log("Player is initialized");
    }
  }, []);
  const [duration, setDuration] = useState(0); // Duration in seconds
  const handleDuration = (duration) => {
    setDuration(duration);
  };
  useEffect(() => {
    console.log("Thumbnail state updated: ", thumbnail);
  }, [thumbnail]);

  const handleProgress = (progress) => {
    setPlayed(progress.played);
    setCurrentTime(progress.playedSeconds);
  };

  const handleForward = () => {
    const player = playerRef.current;
    if (player) {
      const currentTime = player.getCurrentTime();
      const duration = player.getDuration();
      if (currentTime !== null && duration !== null) {
        const newTime = Math.min(currentTime + 15, duration);
        player.seekTo(newTime);
        console.log("Forward - New Time:", newTime);
      } else {
        console.error("Forward - Current time or duration is null");
      }
    } else {
      console.error("Forward - Player ref is null");
    }
  };

  const handleBackward = () => {
    const player = playerRef.current;
    if (player) {
      const currentTime = player.getCurrentTime();
      if (currentTime !== null) {
        const newTime = Math.max(currentTime - 15, 0);
        player.seekTo(newTime);
        console.log("Backward - New Time:", newTime);
      } else {
        console.error("Backward - Current time is null");
      }
    } else {
      console.error("Backward - Player ref is null");
    }
  };

  return (
    <>
      <Grid
        sx={{
          maxWidth: "100vw",
          minHeight: "100vh",
          backgroundColor: "#1b0a28",
          position: "relative",
          overflow: "hidden",

          paddingTop: { xs: "8rem", sm: "12rem", md: "12rem" },

          paddingLeft: { xs: "1rem", sm: "2rem", md: "2rem", lg: "5rem" },
          paddingRight: { xs: "1rem", sm: "2rem", md: "2rem", lg: "5rem" },
          // paddingTop: "12rem",
          // paddingLeft: "5rem",
          // paddingRight: "5rem",
          // paddingBottom: '10rem'
        }}
      >
        {/* Background image for 500 error */}
        {error500 && (
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh", // Full viewport height to center the image vertically
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: 10, // Ensure it appears above the normal content
              display: "flex",
            }}
          >
            <img
              src={five}
              alt="Error 500"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}

        {/* Render 404 Error Image */}
        {error404 && (
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "70vh", // Full viewport height to center the image vertically
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: 10, // Ensure it appears above the normal content
              display: "flex",
            }}
          >
            <img
              src={four}
              alt="Error 404"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
        {!error500 && !error404 && (
          <>
            <Grid classes="home_gradient" />
            <Grid classes="live_bottom_gradient"></Grid>
            <Grid classes="center_gradient" />
            <Grid
              container
              spacing={5}
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Grid
                item
                xs={12}
                md={7}
                sm={12}
                lg={8}
                xl={9}
                ref={containerRef}
                style={{
                  paddingTop: isFullscreen ? "0px" : "40px",
                  paddingBottom: isFullscreen ? "0px" : "5rem",
                }}
              >
                <div
                  className="custom-scrollbar"
                  style={{
                    position: "relative",
                    paddingTop: "56.25%", // 16:9 aspect ratio
                    borderRadius: "25.271px 25.271px 0px 0px",
                    backgroundColor: "#000",
                    overflow: "hidden", // Ensure the border radius is applied correctly
                  }}
                  ref={videoRef}
                  onMouseEnter={() => setShowControls(true)}
                  onMouseLeave={() => setShowControls(false)}
                >
                  {currentVideoUrl ? (
                    <ReactPlayer
                      ref={playerRef}
                      url={currentVideoUrl}
                      width="100%"
                      height="100%"
                      playing={playing}
                      controls={false}
                      volume={muted ? 0 : volume}
                      onProgress={handleProgress}
                      onDuration={handleDuration}
                      onReady={() => console.log("Player is ready")}
                      onError={(error) => console.error("Player error:", error)}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    />
                  ) : (
                    <p>Loading video...</p>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      left: "20px",
                      zIndex: 1,
                      color: "white",
                      fontSize: "16px",
                      lineHeight: "1px",
                      marginBottom: "1rem",
                    }}
                  >
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </div>

                  {showControls && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "67.85px",
                          height: "67.85px",
                          borderRadius: "50%",
                          position: "relative",
                          background: "rgba(0, 0, 0, 0.25)",
                          backdropFilter: "blur(10.76px)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                        onClick={handleBackward}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            width: "67.85px",
                            height: "67.85px",
                            top: "0",
                            left: "0",
                            padding: "1.5px",
                            zIndex: "20",
                            background:
                              "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                            borderImageSlice: "1",
                            mixBlendMode: "overlay",
                            WebkitMask:
                              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                            WebkitMaskComposite: "xor",
                            maskComposite: "exclude",
                          }}
                        />
                        <Box
                          sx={{
                            position: "relative",
                            zIndex: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={handleBackward}
                        >
                          <img
                            src={backwardButton}
                            alt="backwardButton"
                            style={{
                              width: "50%", // Adjust the size of the image as needed
                              height: "50%", // Ensure the height matches the width
                              objectFit: "contain",
                              color: "white",
                            }}
                            onClick={handleBackward}
                          />
                        </Box>
                      </Box>
                      {playing ? (
                        <Box
                          sx={{
                            width: "110px",
                            height: "110px",
                            borderRadius: "50%",
                            position: "relative",
                            background: "rgba(0, 0, 0, 0.25)",
                            backdropFilter: "blur(10.76px)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            margin: "auto",
                            // Ensure the box is centered properly within its parent
                          }}
                          onClick={handlePlayPause}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              borderRadius: "50%",
                              width: "110px",
                              height: "110px",
                              top: "0",
                              left: "0",
                              padding: "1.5px",
                              zIndex: "20",
                              background:
                                "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                              borderImageSlice: "1",
                              mixBlendMode: "overlay",
                              WebkitMask:
                                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                              WebkitMaskComposite: "xor",
                              maskComposite: "exclude",
                              // Removed WebkitMask and maskComposite as they're not needed
                            }}
                          />
                          <Box
                            sx={{
                              position: "relative",
                              zIndex: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <img
                              src={pause}
                              alt="Pause Icon"
                              style={{
                                width: "50%",
                                height: "50%",
                                objectFit: "contain",
                                color: "white",
                              }}
                              onClick={handlePlayPause}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "110px",
                            height: "110px",
                            borderRadius: "50%",
                            position: "relative",
                            background: "rgba(0, 0, 0, 0.25)",
                            backdropFilter: "blur(10.76px)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            margin: "auto",
                            // Ensure the box is centered properly within its parent
                          }}
                          onClick={handlePlayPause}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              borderRadius: "50%",
                              width: "110px",
                              height: "110px",
                              top: "0",
                              left: "0",
                              padding: "1.5px",
                              zIndex: "20",
                              background:
                                "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                              borderImageSlice: "1",
                              mixBlendMode: "overlay",
                              WebkitMask:
                                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                              WebkitMaskComposite: "xor",
                              maskComposite: "exclude",
                              // Removed WebkitMask and maskComposite as they're not needed
                            }}
                          />
                          <Box
                            sx={{
                              position: "relative",
                              zIndex: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <PlayArrowIcon
                              fontSize="large"
                              style={{
                                fontSize: "4rem",
                                color: "white",
                                cursor: "pointer",
                                margin: "0 15px",
                              }}
                              onClick={handlePlayPause}
                            />
                          </Box>
                        </Box>
                      )}
                      <Box
                        sx={{
                          width: "67.85px",
                          height: "67.85px",
                          borderRadius: "50%",
                          position: "relative",
                          background: "rgba(0, 0, 0, 0.25)",
                          backdropFilter: "blur(10.76px)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                          margin: "auto",
                        }}
                        onClick={handleForward}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            width: "67.85px",
                            height: "67.85px",
                            top: "0",
                            left: "0",
                            padding: "1.5px",
                            zIndex: "20",
                            background:
                              "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                            borderImageSlice: "1",
                            mixBlendMode: "overlay",
                            WebkitMask:
                              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                            WebkitMaskComposite: "xor",
                            maskComposite: "exclude",
                          }}
                        />
                        <Box
                          sx={{
                            position: "relative",
                            zIndex: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <img
                            src={forwardIcon}
                            alt="forwardIcon"
                            style={{
                              width: "50%", // Adjust the size of the image as needed
                              height: "50%", // Ensure the height matches the width
                              objectFit: "contain",
                              color: "white",
                            }}
                          />
                        </Box>
                      </Box>
                    </div>
                  )}

                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      zIndex: 1,
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={handleVolumeToggle}
                    >
                      {muted ? (
                        <img src={SoundOff} alt="SoundOff" fontSize="large" />
                      ) : (
                        <img src={soundUp} alt="soundUp" fontSize="large" />
                      )}
                    </button>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "43.85px",
                        height: "43.85px",
                        borderRadius: "50%",
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.25)",
                        // backdropFilter: 'blur(10.76px)',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={handleFullscreen}
                      // onClick={toggleFullscreen}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          borderRadius: "50%",
                          width: "43.85px",
                          height: "43.85px",
                          top: "0",
                          left: "0",
                          padding: "1.5px",
                          zIndex: "20",
                          background:
                            "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                          borderImageSlice: "1",
                          mixBlendMode: "overlay",
                          WebkitMask:
                            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                          WebkitMaskComposite: "xor",
                          maskComposite: "exclude",
                          cursor: "pointer",
                        }}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      >
                        {/* <button
     onClick={handleFullscreen}
     style={{
       background: "none",
       border: "none",
       cursor: "pointer",
     }}
   > */}
                        <img
                          // src={bigscreenIcon}
                          // alt="bigscreenIcon"

                          src={isFullscreen ? Resize : bigscreenIcon}
                          alt={isFullscreen ? "Resize" : "bigscreenIcon"}
                          fontSize="large"
                          style={{ color: "white" }}
                        />
                        {/* </button> */}
                      </Box>
                    </Box>
                  </div>
                </div>

                {/* Progress Bar Container */}
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    // ref={scrubberRef}
                    // onClick={handleScrubberChange}
                    ref={progressBarRef}
                    onClick={handleScrub}
                    onMouseMove={handleMouseMove}
                    style={{
                      position: "absolute",
                      bottom: "0px", // Adjust this value to position the scrubber correctly
                      left: "0",
                      width: "100%",
                      height: "6.43px",
                      borderRadius: "25.27px",
                      background: "#F9F9F980",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: 0,
                        height: "100%",
                        width: `${played * 100}%`,
                        background: "#E650FF",
                        borderRadius: "25.27px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "-11px",
                        left: `${played * 100}%`,
                        transform: "translate(-50%, 0)",
                        width: "29.46px",
                        height: "29.46px",
                        borderRadius: "31.01px",
                        border: "2.33px solid #F9F9F9",
                        background: "#F9F9F9",
                        cursor: "pointer",
                        zIndex: 2,
                      }}
                    />
                  </div>
                </div>

                <div style={{ paddingTop: "5rem", position: "relative" }}>
                  <Grid container alignItems="center" spacing={5}>
                    <Grid item>
                      <img
                        src={thumbnail}
                        alt="Comforter"
                        style={{
                          height: "100%",
                          width: "300px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                      />
                    </Grid>
                    <Grid item style={{ position: "relative", top: "-10px" }}>
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: "400",
                          textAlign: "left",
                          color: "white",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        Special For You
                      </h2>
                      <h1
                        style={{
                          fontFamily: "Inter",
                          fontSize: "36px",
                          fontWeight: "600",
                          lineHeight: "54px",
                          textAlign: "center",
                          margin: 0,
                          color: "white",
                        }}
                      >
                        <div style={{ marginTop: "1rem" }}>
                          <Divider
                            sx={{
                              width: "115.5px",
                              height: "6px",
                              borderRadius: "7.5px",
                              opacity: "0.8",
                              background: "rgba(230, 80, 255, 1)",
                              // marginLeft: '12.5rem',
                            }}
                          />
                        </div>
                        {/* Comforter Digital Channel (Tamil) */}
                        {selectedTitle}
                      </h1>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={5} sm={7} lg={4} xl={3}>
                <Divider
                  sx={{
                    width: "126.55px",
                    height: "9.04px",
                    background: "#D226A2",
                    marginBottom: "1rem",
                    position: "relative",
                  }}
                />
                <Typography
                  variant="h2"
                  sx={{
                    color: "#E6E6E6",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontWeight: "700",
                    letterSpacing: "-0.565px",
                    paddingTop: "1rem",
                    position: "relative",
                  }}
                >
                  Channels
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "30px",
                    position: "relative",
                    background:
                      "linear-gradient(181.35deg, rgba(80, 76, 129, 0.5) 1.15%, rgba(179, 177, 200, 0.216098) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                    mt: 5,
                    padding: "6px",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "32px",
                      width: "101%",
                      height: "100%",
                      top: "-2px",
                      left: "-2px",
                      right: "2px",
                      background:
                        "linear-gradient(147.91deg, #FFFFFF 9.57%, rgba(255, 255, 255, 0) 56.98%, rgba(255, 255, 255, 0.77) 97.79%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      padding: "2px",
                      zIndex: "20",
                      overflow: "hidden",
                      pointerEvents: "none", // Disable pointer events on the overlay
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "2rem",
                      paddingLeft: "0.5rem",
                      paddingRight: "0.5rem",
                      paddingBottom: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      maxHeight: "100vh", // Set max height for scrollable area
                      overflowY: "hidden", // Hide scroll by default
                      "&:hover": {
                        overflowY: "auto", // Enable scrolling on hover
                      },
                      "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar in webkit-based browsers
                      },
                      scrollbarWidth: "none", // Hide scrollbar in Firefox
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        // paddingTop: "2.1rem",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                        gap: "1rem",
                      }}
                    >
                      {channels.map((channel) => (
                        <Card
                          // key={channel.content_id}
                          key={channel.content_id}
                          onClick={() =>
                            handleCardClick(
                              channel.content_title,
                              channel.url.url_hls,
                              channel.thumbnail
                            )
                          }
                          // onClick={() => handleClick(channel.url.url_hls)}
                          sx={{
                            width: "100%",
                            height: "125.53px",
                            padding: "10.412px",
                            display: "flex",
                            alignItems: "center",
                            gap: "9.412px",
                            borderRadius: "9.412px",
                            background: "rgba(196, 196, 196, 0.10)",
                            backdropFilter: "blur(17.647123336791992px)",
                            boxShadow: "none",
                            position: "relative", // Ensure it's relatively positioned
                            zIndex: 30, // Adjust zIndex to be higher than the overlay
                            cursor: "pointer", // Add cursor pointer to show it's clickable
                            marginBottom: "1rem",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={channel.thumbnail}
                            alt={channel.content_title}
                            style={{
                              width: "auto",
                              height: "100%",
                              borderRadius: "9.41px",
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#FFF",
                              fontFamily: "Inter",
                              fontSize: "1rem",
                              fontWeight: 600,
                              lineHeight: "150%",
                            }}
                          >
                            {channel.content_title}
                          </Typography>
                          <Button
                            sx={{
                              width: "auto",
                              height: "auto",
                              minWidth: "59.64px",
                              padding: "0 10px",
                              position: "absolute",
                              bottom: "9.412px",
                              right: "9.412px",
                              background: "rgba(22, 18, 42, 1)",
                              color: "white",
                              textTransform: "initial",
                              fontFamily: "Inter",
                              fontSize: "0.75rem",
                              borderRadius: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            Live
                            <div
                              style={{
                                width: "8px",
                                height: "8px",
                                backgroundColor: "red",
                                borderRadius: "50%",
                                marginLeft: "5px",
                                animation: "blink 1s infinite",
                              }}
                            />
                          </Button>
                        </Card>
                      ))}
                    </Box>
                  </Box>

                  <style jsx global>{`
                    @keyframes blink {
                      0%,
                      100% {
                        opacity: 1;
                      }
                      50% {
                        opacity: 0;
                      }
                    }

                    @media (max-width: 768px) {
                      .card {
                        flex-direction: column;
                        height: auto;
                      }
                      .card img {
                        width: 100%;
                        height: auto;
                      }
                      .card button {
                        width: auto;
                        font-size: 0.7rem;
                      }
                    }
                  `}</style>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default LiveTv;
