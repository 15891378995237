import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { apiEndpoint } from "../../components/data/config";
import "../../styles.css"; // Import your custom CSS file here

import Content from "../landingPage/content";
import Carousels from "../landingPage/carousels";
import Card from "../landingPage/card";

import { Grid, Button } from "@mui/material";

import { web_landingpage } from "./landingState";
import { useNavigate } from "react-router-dom";
import playButton from "../images/Vector (1).png";

import axios from "axios";
import five from "../images/five.png";
import four from '../images/400.png'
import CryptoJS from "crypto-js";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

import { auth } from "../../fireBase/setup"; // Import the initialized auth object

import AuthDialog from "../../components/AuthDialog";
function SampleNextArrow(props) {
  return <div style={{ display: "none" }} />;
}

function SamplePrevArrow(props) {
  return <div style={{ display: "none" }} />;
}

const Banner = () => {
  const [bannerData, setBannerData] = useState([]);

  const [otp, setOtp] = useState("");

  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(null);
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleCloseCode = () => {
    setCode(false);
  };

  const [phone, setPhone] = useState("+91");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [resendTimer, setResendTimer] = useState(55);

  // Effect to handle countdown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);
  //Profile

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500, // Increased speed for smoothness
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true, // Enables fade effect for smoother transitions
    pauseOnHover: false, // Disable pause on hover
    responsive: [
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  useEffect(() => {
    web_landingpage()
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0) {
          const bannerSection = data.find(
            (section) => section.template_type === "BANNER"
          );
          if (bannerSection && bannerSection.list) {
            setBannerData(bannerSection.list);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response) {
          if (err.response.status === 500) {
              setError500(true); // Handle 500 error
          } else if (err.response.status === 404) {
              setError404(true); // Handle 404 error
          }
          else if(err.response.status === 401){
            localStorage.removeItem("auth_token");
            navigate("/");
    
            // Optionally reload the page to ensure UI state updates
            window.location.reload();
          }
      }
      });
  }, []);

  const generateRecaptcha = () => {
    // Ensure the recaptchaVerifier is only initialized once
    if (!window.recaptchaVerifier) {
      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        console.error("recaptcha-container element not found in DOM.");
        return;
      }

      if (!auth) {
        console.error("Firebase auth instance not initialized.");
        return;
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA solved", response);
          },
        },
        auth
      );

      window.recaptchaVerifier
        .render()
        .then((widgetId) => {
          window.recaptchaWidgetId = widgetId;
        })
        .catch((error) => {
          console.error("Error rendering reCAPTCHA widget:", error);
        });
    } else {
      console.log("reCAPTCHA already rendered.");
    }
  };
  useEffect(() => {
    generateRecaptcha();
  }, []);

  const handleSend = (event) => {
    event.preventDefault();
    setCode(true);

    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setCode(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };
  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    const token = localStorage.getItem("auth_token"); // Check if token exists in localStorage

    if (token) {
      const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

      // Encrypt the query string
      const encryptedQuery = encryptQueryString(queryString);

      // Navigate to the new page with the encrypted query string
      navigate(`/videoPlayer?data=${encryptedQuery}`);
    } else {
      // User is not logged in, show alert and stay on the page
      setOpen(true);
    }
    // Construct the query string
  };

  const login = async () => {
    const provider = new GoogleAuthProvider();

    try {
      // Trigger Google Sign-In popup
      const result = await signInWithPopup(auth, provider);

      // Get user info from Google result
      const user = result.user;
      const token = await user.getIdToken(); // Firebase token if needed for future use

      const userData = {
        name: user.displayName,
        email: user.email,
        phone_number: user.phoneNumber,
        birth_date: "1990-01-01", // Example birth date, change as required
      };

      // Post user data to your custom backend API
      const response = await axios.post(
        `${apiEndpoint}/api/app_social_login`,
       
      
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Optional: if you want to pass the Firebase token
          },
        }
      );

      if (response.status === 200) {
        // Check the structure of response.data
        console.log("Login response data:", response.data);

        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure

        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored in local storage:", backendToken);

          // Handle successful login response
          setOpen(false);
          navigate("/homePage");
          // alert("Login is successful");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        // Handle non-200 status
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };

  const verifyOtp = async () => {
    try {
      // Use the phone state instead of hardcoding the phone number
      const payload = {
        phone_number: phone, // The phone variable is dynamically updated from the input field
      };
      // Post user data to your custom backend API
      const response = await axios.post(
        `${apiEndpoint}/api/app_otp_login`,
        payload
      );
      console.log("API Response Status:", response.status);
      console.log("API Response Data:", response.data);
      if (response.status === 200) {
        navigate("/homePage");
        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure
        navigate("/homePage");
        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored in local storage:", backendToken);
          // Handle successful login response
          // You can add further actions here, e.g., navigate to a different page
        } else {
          console.error("No token found in response data.");
          alert("Login failed: No token returned.");
        }
      } else {
        console.error("Failed to login", response.status, response.data);
        alert("Login failed. Please check your details.");
      }
    } catch (error) {
      // Handle errors during API call
      console.error("Error during API call:", error);
      alert("Error during API call. Please try again.");
    }
  };

  const handleResendCode = () => {
    // Logic to resend OTP
    console.log("Resending OTP...");
    // Reset the timer
    setResendTimer(55);
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    if (value.length === 1) {
      // Update OTP array and move focus to next input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      focusNext(index);
    } else if (value.length === 0) {
      // Update OTP array and move focus to previous input
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      focusPrevious(index);
    }
  };
  const inputRefs = useRef([]);

  // Function to focus the next input field
  const focusNext = (index) => {
    if (index < verificationCode.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Function to focus the previous input field
  const focusPrevious = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
      <div
        style={{
          // width: "100vw",
          maxWidth: "100vw",
          minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
          backgroundColor: "#1b0a28",
          position: "relative",
          overflowX: "hidden",
        }}
      >
          {/* Background image for 500 error */}
          {error500 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={five}
                        alt="Error 500"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/* Render 404 Error Image */}
            {error404 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={four}
                        alt="Error 404"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}
              {!error500 && !error404 && (
                <>
        <div style={{ position: "relative" }}>
          <Slider {...settings}>
            {bannerData.map((item, index) => (
              <div key={index} style={{ position: "relative" }}>
                {/* <div style={overlayStyle}></div> Overlay div */}
                <Grid
                  sx={{
                    width: "3%",
                    height: "560px",
                    borderRadius: "560px",
                    top: "-148",
                    left: "-91.86", // Position from the left edge of the image
                    // Center the overlay horizontally and vertically
                    background: "rgba(118, 64, 232, 0.5)", // Semi-transparent overlay color
                    position: "absolute", // Position absolutely within the parent container
                    filter: "blur(140px)",
                  }}
                />
                <div style={data}></div>

                <div style={overlayStyles}></div>
                <Grid
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <img
                    src={item.slider_image} // Assuming the image URL is in `slider_image`
                    alt={`Banner${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "none",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    width: {
                      xs: "100%", // Full width on small screens
                    },
                    // height: {
                    //   xs: "auto",  // Adjust height based on the width
                    // },
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      borderRadius: "8px",
                      width: "100%",
                      height: "0",
                      paddingBottom: "150.25%", // This maintains a 16:9 aspect ratio
                    }}
                  >
                    <img
                      src={item.slider_image}
                      alt={`Banner ${index}`}
                      style={{
                        position: "absolute", // Make the image fill the container
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Image covers the container
                      }}
                    />
                  </div>
                </Grid>

                {/* <p>ஜே</p> */}
                <Grid container>
                  <Grid
                    sx={{
                      position: "absolute",
                      top: "28%",
                      width: "638px",
                      height: "309px",

                      marginLeft: "11rem",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "flex",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>
                  <Grid
                    sx={{
                      position: "absolute",
                      top: "28%",
                      marginLeft: "5rem",
                      width: "638px",
                      height: "220px",

                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "flex",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid
                    sx={{
                      position: "absolute",
                      top: "28%",
                      marginLeft: "5rem",
                      //  height:'40%',
                      width: "638px",
                      height: "190px",

                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid
                    sx={{
                      position: "absolute",
                      top: "30%",
                      marginLeft: "2rem",
                      //  height:'40%',
                      width: "638px",
                      height: "150px",

                      display: {
                        xs: "none",
                        sm: "flex",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid
                    sx={{
                      position: "absolute",
                      top: "40%",
                      marginLeft: "5rem",
                      height: "106px",
                      marginTop: "175px",

                      display: {
                        xs: "flex",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid classes="home_button_gradients"></Grid>
                  <Grid
                    sx={{
                      position: "absolute",

                      transform: "translate(-50%, -50%)",
                      zIndex: 2,
                      top: { xs: "60%", sm: "75%", md: "75%", lg: "68%" },
                      left: { xs: "50%", sm: "75%", md: "75%", lg: "76%" },
                    }}
                  >
                    <Button
                      sx={{
                        background:
                          "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                        color: "#fff",
                        border: "1.597px solid transparent",
                        borderRadius: "60px",
                        textTransform: "initial",
                        fontSize: {
                          xs: "16px",
                          sm: "25px",
                          md: "25px",
                          lg: "25px",
                        },
                        fontWeight: 700,
                        lineHeight: "38.73px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontFamily: "Inter !important",
                        boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
                        position: "absolute",
                        opacity: 9.85,
                        zIndex: 9, // Higher zIndex for button
                        height: {
                          xs: "40px",
                          sm: "55px",
                          md: "55px",
                          lg: "55px",
                        },
                        backdropFilter: "blur(1px)",
                        padding: {
                          xs: "0px 25px",
                          sm: "0px 45px",
                          md: "0px 45px",
                          lg: "0px 45px",
                        },
                      }}
                      onClick={() =>
                        handleClick(item.content_id, item.content_type_id)
                      }
                    >
                      <img
                        src={playButton}
                        alt="playButton"
                        style={{ marginRight: "10px" }}
                      />
                      Watch
                    </Button>
                  </Grid>
                </Grid>

                {/* </div> */}
              </div>
            ))}
          </Slider>
        </div>

        <Card />
        {/* <RecentlyAdded />  */}

        <Carousels />

        <Content />
        </>)}
      </div>
    
      <AuthDialog
        open={open}
        handleCloseDialog={handleCloseDialog}
        handleSend={handleSend}
        phone={phone}
        setPhone={setPhone}
        code={code}
        handleCloseCode={handleCloseCode}
        verificationCode={verificationCode}
        setVerificationCode={setVerificationCode}
        otp={otp}
        setOtp={setOtp} // Pass setOtp here
        handleInputChange={handleInputChange}
        verifyOtp={verifyOtp}
        resendTimer={resendTimer}
        handleResendCode={handleResendCode}
        login={login}
      />
    </>
  );
};

export default Banner;

const data = {
  width: "100%",
  // maxWidth:'100vw',
  height: "239px",
  top: "-148",
  left: "-91.86", // Position from the left edge of the image
  // Center the overlay horizontally and vertically
  // background: 'rgba(118, 64, 232, 0.5)', // Semi-transparent overlay color
  background:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)",
  position: "absolute", // Position absolutely within the parent container
  filter: "blur(20px)",
};

const overlayStyles = {
  width: "100%",
  height: "50%",
  top: "50%",

  // left: "-91.86",

  background:
    "linear-gradient(180.1deg, rgba(27, 10, 40, 0) 44.79%, rgba(27, 10, 40, 0.401553) 56.48%, rgba(27, 10, 40, 0.674889) 67.63%, #1B0A28 86.49%)",
  position: "absolute",
  filter: "blur(1px)",
};
